.full-sheet {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: $gutter--sm;
  padding-top: calc(#{$gutter--sm} + var(--ion-safe-area-top));
  padding-bottom: calc(#{$gutter--md} + var(--ion-safe-area-bottom));
  background-color: $color-tertiary--white;
  z-index: 10;

  > * {
    width: 100%;
  }

  &__loader {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__logo {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo--air-miles {
      width: 200px;
      height: 200px;
    }
  }
}
