.footer {
  padding-bottom: $gutter--sm * 1.25;

  @include breakpoint($break--sm, max) {
    padding-top: $gutter--md;
  }

  &-container {
    display: block;
    background: $color-tertiary--white;
    margin-top: $gutter--xxlg;
    padding-bottom: env(safe-area-inset-bottom);
  }

  &-nav {
    margin-top: $gutter--xlg;

    @include breakpoint($break--sm, max) {
      margin-top: $gutter--md;
    }

    air-link-list {
      & > .link-list__group {
        @extend .grid;
      }

      .link-list {
        &__section {
          @extend .col-3;
          @extend .col-3--md;
          @extend .col-2--sm;
          margin-bottom: $gutter--md;
          margin-right: $gutter--sm;

          &__title {
            @extend h5;
            color: var(--color-primary);
          }
        }

        &__links__item {
          margin: $gutter--sm 0;
          line-height: $line-height--lg;
          transition: text-decoration-color 0.4s $transition-easing--base;

          &.active,
          &:active {
            text-decoration: underline;
          }

          @media (hover: hover) {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      span.link-list__section__title {
        @extend .h5;
      }
    }
  }

  a:not(.button) {
    color: $color-tertiary--black;
    text-decoration: none;
  }

  &-end {
    letter-spacing: $line-height--md;
    padding: $gutter--lg 0;
    font-size: $font-size--sm;

    & > * {
      margin: 0 0 $gutter--xsm;
    }

    @include breakpoint($break--md, max) {
      padding: 0;
    }

    @include breakpoint($break--sm, max) {
      padding-bottom: $mobile-navbar-height;
    }

    &__social {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $gutter--md;
      margin-top: $gutter--lg;
      margin-bottom: $gutter--lg;

      @include breakpoint($break--sm, max) {
        flex-basis: 100%;
        flex-direction: column;
        gap: $gutter--lg;
        align-items: center;
      }

      h3 {
        color: $color-primary--blue;
        margin: 0;
      }

      a:hover:after {
        content: none;
      }

      &-list {
        display: flex;
        gap: $gutter--md;
      }
    }

    &__social-buttons {
      display: flex;
      gap: $gutter--md;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__nav {
      margin-top: $gutter--lg;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include breakpoint($break--md, max) {
        flex-direction: column-reverse;
        gap: $gutter--xlg;
      }

      .link-list__links {
        width: fit-content;
        display: flex;
        flex-wrap: wrap;
        gap: $gutter--lg;
        align-items: center;
        margin: 0 auto;

        a {
          font-size: $font-size--sm;
        }

        @include breakpoint($break--md, max) {
          padding-right: 60px; // Indent for chat-icon
        }
      }
    }
  }
  &__pay-off {
    height: 48px;
    width: 168px;
  }
}
