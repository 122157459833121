@charset "UTF-8";

/* Load config for variables */
@import "config";

/* Import Elastique Stretch core, for mixins and utilities */
@import "elastique-stretch-core/source/scss/core";

/* Base partials */
@import "base/font", "base/grid", "base/layout", "base/typography", "base/print", "base/display", "base/themes", "base/embedded";

/* Components partials */
@import "components/footer", "components/header", "components/navigation", "components/breadcrumbs", "components/content",
  "components/partner", "components/hero", "components/cookie-banner", "components/toast", "components/modal", "components/login-modal",
  "components/password-reset-modal", "components/carousel", "components/select-count", "components/full-sheet",
  "components/app-consent-sheet", "components/navigation--mobile", "components/maintenance", "components/playable-widget",
  "components/language-switcher", "components/typeform";

/* Content block partials */
@import "content-blocks/formatted-text", "content-blocks/highlighted-card", "content-blocks/card-list", "content-blocks/partners-list",
  "content-blocks/partner-logos", "content-blocks/expandable-list", "content-blocks/registration-form", "content-blocks/product-overview",
  "content-blocks/password-reset", "content-blocks/highlighted-promotions", "content-blocks/transfer-points",
  "content-blocks/terminate-account", "content-blocks/organization-overview", "content-blocks/purchased-vouchers",
  "content-blocks/expirations", "content-blocks/share-account", "content-blocks/video", "content-blocks/donation-counter",
  "content-blocks/banner", "content-blocks/share-referral-link", "content-blocks/inbox", "content-blocks/text-list";

/* Elements styling partials */
@import "elements/button", "elements/divider", "elements/icon", "elements/sticker", "elements/image", "elements/loader", "elements/form",
  "elements/list", "elements/card", "elements/pagination", "elements/input", "elements/table", "elements/badge", "elements/slider",
  "elements/file", "elements/progress", "elements/counter", "elements/title";

/* Features styling partials */
@import "features/product/", "features/dashboard/", "features/personal", "features/payment", "features/search", "features/promotions";

/* Page specific styling */
@import "pages/style-guide", "pages/product", "pages/ticket-template-page", "pages/campaign-page";

/* Branding/theme specific partials */
@import "theme/branding", "theme/chat", "theme/shell";

// Flag icons
@import "/node_modules/flag-icons/css/flag-icons.min.css";
