.payment-receipt {
  .user-details {
    .form-group {
      flex-direction: column;
      align-items: flex-start;

      > * {
        margin-bottom: $gutter--sm;
        font-weight: $font-weight--bold;

        &.label {
          font-weight: $font-weight--normal;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  a:not(.button) {
    margin-top: $gutter--xsm;
    font-size: $font-size--sm;
    text-decoration: underline;
    color: var(--color-primary);
  }

  .form-group {
    & > #{$headings} {
      flex: 0 1 50%;
    }
  }

  input + label:before {
    background-color: $color-tertiary--white;
  }

  .payment__cta {
    pointer-events: none;
    padding-bottom: $gutter--sm;

    &:first-child {
      border-top: 2px solid var(--color-primary-light);
      padding-top: $gutter--sm;
    }

    a,
    input,
    label,
    button {
      &:not([class*="disabled"]):not([disabled]) {
        pointer-events: all;
      }
    }
  }
}
