air-mobile-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: z-index("navigation");

  air-navigation {
    display: block; // Necessary for sheet animation to work correctly on keyboard show/hide.
  }

  @at-root .keyboard--open & {
    display: none;
  }
}

/* 
 * MOBILE SPECIFIC 
 */
.nav {
  &--mobile {
    &:after {
      content: "";
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $color-tertiary--black-alpha-06;
      z-index: -2;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.25s $transition-easing--base;
    }

    @at-root .nav--open &:after {
      opacity: 1;
    }

    .link-list__section > .link-list {
      opacity: 1;
      visibility: visible;
      transition: $transition--appear;
      left: 0;
    }

    air-link-list {
      width: 100vw;
      height: calc(#{$mobile-navbar-height} + var(--ion-safe-area-bottom));
      background-color: var(--color-primary);
      display: flex;
      flex-wrap: nowrap;

      & > * {
        flex: 1 0 0;
      }

      & > .link-list__group {
        & > .link-list__section > .link-list {
          position: absolute;
          width: 100vw;
          bottom: calc(#{$mobile-navbar-height} + var(--ion-safe-area-bottom));
          left: 0;
          background-color: $color-tertiary--white;
          border-top-left-radius: $border-radius--base;
          border-top-right-radius: $border-radius--base;
          box-shadow: var(--box-shadow--level3);
          padding: $gutter--sm $gutter--xsm $gutter--xsm * 1.5;
          transform: translateY(100%);
          z-index: -1;
        }

        & > .link-list__links .link-list__links__item,
        & > .link-list__section > .link-list__section__item {
          width: 100%;
          border-radius: $border-radius--sm;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: $gutter--xsm * 0.5;

          i {
            margin-bottom: $gutter--xsm * 0.5;
            background-color: $color-tertiary--white;
          }

          & > a span,
          & > span {
            font-size: $font-size--sm;
            color: $color-tertiary--white;
          }

          & > a,
          & > span {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0;
            border-radius: $border-radius--sm;
            i {
              order: -1;
            }
          }

          &:after {
            content: none;
          }

          &.active,
          &:active {
            background-color: var(--color-primary-dark);
          }

          @media (hover: hover) {
            &:hover {
              background-color: var(--color-primary-dark);
            }
          }
        }

        & > .link-list__section {
          position: static;

          &.section--open {
            background-color: var(--color-primary-dark);

            & > .link-list {
              opacity: 1;
              transform: translateY(0%);
              visibility: visible;
            }
          }
        }
      }
    }

    .link-list {
      opacity: 1;
    }

    .link-list__section .link-list__section {
      @extend %subMenuItem;

      .link-list {
        padding-left: $gutter--md;
        transform: none;
        visibility: visible;
        opacity: 1;
        position: relative;
        box-shadow: none;
        top: 0;
        left: 0;
      }
    }

    .link-list__section .link-list .link-list__links__item,
    .link-list__section .link-list .link-list__section__item {
      width: 100%;

      &.active,
      &:active {
        background-color: var(--color-primary-lighter);
        &:after {
          background-color: var(--color-primary);
        }
      }

      &:after {
        margin-right: $gutter--sm;
      }
    }
  }
}
