$transition--disappear:
  opacity 0.25s $transition-easing--base,
  visibility 0s 0.25s,
  transform 0.25s $transition-easing--base;
$transition--appear:
  opacity 0.25s $transition-easing--base,
  visibility 0s 0s,
  transform 0.25s $transition-easing--base;

%subMenuItem {
  & > a,
  & > span {
    color: var(--color-primary);
    text-decoration: none;
    padding: $gutter--xsm * 1.5;
    display: block;
    cursor: pointer;
    line-height: $line-height--md * 1.1;
  }
}

.nav {
  font-family: $font-family--mic32;

  &-container {
    z-index: z-index("navigation");
  }

  &__backdrop {
    position: fixed;
    // Positioned and sized extra, due to inconsistent layout relative to nav-container in different browsers.
    // Now we mak sure it covers the entire window in either case.
    width: 300vw;
    height: 300vh;
    top: -100vh;
    left: -100vw;
    right: 0;
    bottom: 0;
    background-color: $color-tertiary--black-alpha-06;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: $transition--disappear;
    z-index: -1;
  }

  &--open {
    @include breakpoint($break--sm, max) {
      .nav__backdrop {
        visibility: visible;
        opacity: 1;
        transition: $transition--appear;
      }
    }
  }

  span,
  a {
    @extend .h5;
  }

  air-link-list {
    display: flex;
    flex-wrap: nowrap;
  }

  .link-list__links__item,
  .link-list__section__item {
    width: 264px;
    border-radius: $border-radius--base;
    transition: background-color 0.25s $transition-easing--base;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @extend %subMenuItem;

    &.active,
    &:active {
      background-color: var(--color-primary-lighter);
      a {
        i {
          background-color: var(--color-primary);
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-primary-lighter);
        a {
          i {
            background-color: var(--color-primary);
          }
        }
      }
    }

    a {
      width: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      i {
        background-color: var(--color-primary-light);
      }
    }
  }

  .link-list__group .link-list__group {
    &:not(:first-child) {
      border-top: $border--default;
      padding-top: $gutter--xsm;
      margin-top: $gutter--xsm;
    }
  }

  .link-list__section > .link-list {
    opacity: 0;
    visibility: hidden;
    transition: $transition--disappear;
    transform-origin: top center;
    will-change: transform, opacity, visibility;

    .link-list__section__item {
      &:after {
        content: "";
        position: relative;
        width: 20px;
        height: 20px;
        margin-bottom: $gutter--xsm * 0.5;
        margin-right: $gutter--xsm * 0.5;
        @supports (mask: url("#{$icon-path}icon-ui-arrow--right--sm.svg")) {
          mask-image: url("#{$icon-path}icon-ui-arrow--right--sm.svg");
          background-color: var(--color-primary-light);
          transition: background-color 0.25s $transition-easing--base;
        }
      }

      @media (hover: hover) {
        &:hover:after {
          background-color: var(--color-primary-dark);
        }
      }
    }
  }

  @media (hover: hover) {
    @at-root .link-list__section:hover > .link-list {
      opacity: 1;
      visibility: visible;
      transition: $transition--appear;
    }
  }

  .link-list__section.section--open > .link-list {
    opacity: 1;
    visibility: visible;
    transition: $transition--appear;
  }

  .link-list__section {
    color: var(--color-primary);
    position: relative;

    & .link-list__section {
      position: relative;

      // After discussing with Rosa, decided to hide submenus on mobile sizing.
      @include breakpoint($break--sm, max) {
        display: none;
      }

      &__title {
        display: inline-flex;
        padding: $gutter--xsm * 1.5;
        cursor: pointer;
        width: 100%;
        border-radius: $border-radius--base;
        line-height: $line-height--md * 1.1;
        font-family: $font-family--mic32;
      }
    }

    .link-list {
      position: absolute;
      background: $color-tertiary--white;
      padding: $gutter--xsm;
      border-radius: $border-radius--sm;
      display: flex;
      flex-direction: column;
      z-index: -1;
      box-shadow: var(--box-shadow--level3);

      & > .link-list__section > .link-list__group {
        left: 100%;
        top: 0;
        padding-top: $gutter--xsm;
      }

      .link-list {
        top: 0;
        left: 100%;
      }
    }
  }

  /* 
   * PUBLIC SPECIFIC 
   */
  &--public {
    air-link-list {
      display: flex;
      align-items: center;

      & > .link-list__group {
        & > .link-list__section {
          & > .link-list {
            padding-top: $gutter--md;
          }

          & > .link-list__section__item {
            width: auto;
            border-radius: $border-radius--base;
            display: inline-flex;
            z-index: 1;
            transition: background-color 0.25s $transition-easing--base;
            display: flex;
            align-items: center;

            .link-list__section__title {
              color: $color-primary--blue;
            }

            &:after {
              content: "";
              position: relative;
              width: 28px;
              height: 24px;
              margin-right: $gutter--xsm;

              @supports (mask: url("#{$icon-path}icon-ui-arrow--down.svg")) {
                mask-image: url("#{$icon-path}icon-ui-arrow--down.svg");
                background-color: var(--color-primary);
              }
            }
          }

          &.section--open > .link-list__section__item {
            background-color: var(--color-primary-lighter);
          }

          @media (hover: hover) {
            &:hover > .link-list__section__item {
              background-color: var(--color-primary-lighter);
            }
          }

          &:after {
            content: "";
            position: absolute;
            height: 3rem;
            bottom: -2rem;
            width: 100%;
            background: transparent;
            cursor: pointer;
          }
        }

        & > .link-list__links {
          & > .link-list__links__item {
            width: auto;
            position: relative;
            background-color: transparent;
            transition: background-color 0.25s $transition-easing--base;

            a {
              color: var(--nav-link);
              text-decoration: none;
            }
          }

          @media (hover: hover) {
            &:hover > .link-list__links__item {
              background-color: var(--color-primary-lighter);
            }
          }
        }

        & > * {
          margin-right: $gutter--sm;
          position: relative !important;
        }
      }
    }
  }

  /* 
   * DROP DOWN MENUS
   */
  &--personal,
  &--hamburger {
    position: relative;

    i {
      background-color: var(--color-primary);
    }

    .button--menu {
      @extend .h5;
      color: var(--color-primary);
      background: unset;

      & > * {
        pointer-events: none;
      }

      > div {
        display: flex;
        align-items: center;
      }

      // Small overlap to make sure mouse events keep triggering in overlap
      &:after {
        content: "";
        position: absolute;
        height: 3rem;
        bottom: -2rem;
        width: 100%;
        background: transparent;
        cursor: pointer;
      }
    }

    .nav-container {
      position: absolute;
      right: 0;
      opacity: 0;
      visibility: hidden;
      transition: $transition--disappear;
      transform-origin: top center;
      will-change: transform, opacity, visibility;
    }

    .link-list__group {
      &:not(:first-child) {
        border-top: $border--default;
        padding-top: $gutter--xsm;
        margin-top: $gutter--xsm;
      }
    }

    air-link-list {
      display: flex;
      flex-direction: column;
      box-shadow: var(--box-shadow--level3);
      background: $color-tertiary--white;
      border-radius: $border-radius--sm;
    }

    .nav--open.nav-container {
      opacity: 1;
      visibility: visible;
      transition: $transition--appear;
    }

    @media (hover: hover) {
      .nav--open.nav-container {
        opacity: 1;
        visibility: visible;
        transition: $transition--appear;
      }
    }
  }

  /** PERSONAL SPECIFIC **/
  &--personal {
    air-link-list {
      padding: $gutter--md $gutter--xsm $gutter--xsm;
    }

    .button--menu {
      display: flex;
      justify-content: center;

      &:focus,
      &:active {
        background-color: var(--color-primary-lighter);
      }

      @media (hover: hover) {
        &:hover {
          background-color: var(--color-primary-lighter);
        }
      }

      &--logged-in {
        background-color: $color-secondary--green-dark;
        color: $color-tertiary--white;
        display: inline-flex;

        i.icon-ui-login {
          display: none;
          background: $color-tertiary--white;
        }

        &:active {
          background-color: $color-secondary--green;
        }

        @media (hover: hover) {
          &:hover {
            background-color: $color-secondary--green;
          }
        }

        > div {
          display: inline-flex;
        }

        .icon-ui-arrow--down {
          display: none;
          @include breakpoint($break--md, min) {
            display: inline-block;
            background: $color-tertiary--white;
          }
        }

        &.button--menu--no-balance {
          width: 48px;
          height: 48px;

          i.icon-ui-login {
            margin-right: 0;
            display: inline-block;
          }
        }
      }

      @include breakpoint($break--md, max) {
        width: 48px;
        height: 48px;

        i.icon-ui-login {
          margin-right: 0;
          display: inline-block;
        }
      }
    }

    .my-miles {
      margin-right: $gutter--xsm * 0.5625;
      display: flex;
      flex-direction: column;

      span {
        text-align: left;
        line-height: $line-height--md;
        padding: 0;
        margin: 0;
      }

      &__label {
        font-size: $font-size--xsm * 1.25;
      }

      &__total {
        font-size: $font-size--xsm * 2.25;
      }
    }
  }

  /** HAMBURGER SPECIFIC **/
  &--hamburger {
    air-link-list {
      padding: $gutter--xsm;
    }

    .button--menu {
      padding: $gutter--xsm;
      i {
        margin: 0;
      }
    }
  }
}
