:root {
  --app-height: 100%;
  background-color: var(--color-primary-lighter);
}

html,
body {
  height: 100%;
  width: 100%;
  background-color: var(--color-primary-lighter);
  transform: none;
}

[hidden] {
  display: none !important;
}

ion-app {
  width: 100%;
  height: 100%;
}

air-layout {
  width: 100vw;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

ion-content {
  flex: 1 0 0;
  --ion-background-color: var(--color-primary-light);

  @at-root .modal--open & {
    .scroll-content {
      overflow-y: hidden;
    }
  }

  &.searchActive {
    .fixed-box {
      position: fixed;
      width: 100%;
    }
  }
}

.grecaptcha-badge {
  top: calc(102px + var(--ion-safe-area-top));
  bottom: auto;
  visibility: hidden;

  @at-root .sheet--open & {
    display: none !important;
  }
}

@include breakpoint($break--md, max) {
  .grecaptcha-badge {
    top: calc(94px + var(--ion-safe-area-top));
  }
}

@include breakpoint($break--sm, max) {
  .grecaptcha-badge {
    top: calc(78px + var(--ion-safe-area-top));
  }
}
