air-focal-image {
  &.cropped {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.image {
  &--full-width {
    width: 100%;
    height: auto;
  }
}

figure {
  display: block;
  position: relative;
}

figcaption {
  @extend .p;
  text-align: right;
  color: rgba($color-tertiary--black-dark, 0.3);
  margin-top: $gutter--xsm;
}
