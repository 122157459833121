.product-receipt {
  &__border {
    background-color: var(--color-primary-lighter);
    padding: $gutter--sm $gutter--sm;
    border-radius: $border-radius--base;
  }

  h1 {
    margin-bottom: $gutter--xsm;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-top: $gutter--md;

    &-block {
      margin-top: $gutter--xsm;

      &__border {
        @extend .product-receipt__border;
      }

      &__margin {
        margin-bottom: $gutter--md;
      }
    }

    &-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      border-top: $border--default;
      padding-top: $gutter--sm;
      margin-top: $gutter--sm;

      &:first-child {
        border-top: none;
        padding-top: 0;
        margin-top: 0;
      }

      h3,
      h5 {
        padding-top: 0;
        margin-bottom: 0;
        margin-right: $gutter--md;
      }

      &--multi {
        flex-wrap: wrap;

        h3 {
          flex: 0 0 100%;
          padding-bottom: $gutter--sm;

          @include breakpoint($break--md, max) {
            padding-bottom: $gutter--xsm;
            font-size: $font-size--ml;
          }
        }

        & > div {
          display: flex;
          flex-wrap: nowrap;
          flex: 0 0 100%;
          justify-content: space-between;
          align-items: center;
        }

        .sticker {
          margin-top: $gutter--xsm;
          margin-bottom: $gutter--xsm;

          &-group {
            flex: 0 0 auto;
          }
        }
      }

      &--radio {
        align-items: center;
        justify-content: flex-end;
        margin: $gutter--xsm 0;

        &.charity-form {
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-start;

          label {
            margin-right: 0;
          }

          @include breakpoint($break--md, max) {
            flex-direction: column;
            gap: $gutter--sm;
          }
        }

        &--options {
          display: flex;
          flex-direction: column;
          gap: $gutter--xsm;

          input {
            margin-right: $gutter--xsm;
            background-color: $color-tertiary--white;

            &[type="radio"] + label::before,
            &[type="checkbox"] + label::before {
              background-color: $color-tertiary--white;
            }
          }
        }
      }

      &--select {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .select-block {
          width: auto;
          flex: 1 0 200px;
          display: flex;
          align-items: center;
          cursor: pointer;

          select {
            background-color: $color-tertiary--white;
          }

          i {
            position: absolute;
            bottom: unset;
            right: $gutter--sm;
            margin: 0;
            pointer-events: none;
          }
        }
      }
    }

    h3 {
      margin-bottom: 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
    }

    // Create some margin to the air-miles-sticker component if it's a direct child
    & > air-miles-sticker .sticker-group {
      margin-bottom: $gutter--sm;
    }

    &.ng-dirty.ng-invalid {
      counter {
        input {
          border-color: $color-secondary--red;
        }
      }
    }

    input,
    select {
      &.ng-dirty.ng-invalid {
        border-color: $color-secondary--red;
      }
    }
  }

  &__cta {
    &.form-group {
      margin-top: $gutter--sm;
      border-top: none;
      margin-top: 0;
    }

    &--sticky {
      position: fixed;
      bottom: calc(#{$gutter--xlg} + var(--ion-safe-area-bottom));
      left: 0;
      width: 100%;
      height: auto;
      z-index: 3;

      @include breakpoint($break--sm, max) {
        bottom: calc(#{$mobile-navbar-height} + var(--ion-safe-area-bottom));
      }

      @at-root .keyboard--open & {
        visibility: hidden;
      }
    }
  }
  &__donation-campaign {
    display: flex;
    flex-direction: column;
    gap: $gutter--sm;
    flex-grow: 1;
  }
}
