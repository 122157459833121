.formatted-text {
  &__body {
    @extend %textListStyling;

    .button {
      height: auto;

      span {
        white-space: normal;
        padding: 0 $gutter--xsm;
      }
    }
  }

  &--aligned-image {
    display: flex;
  }

  &__cta {
    margin-top: $gutter--sm;

    .button {
      margin-bottom: $gutter--xsm;
    }

    @include breakpoint($break--sm, max) {
      margin-top: $gutter--xsm;
    }
  }
}

%formattedTextImageFlex {
  flex: 0 0 200px;

  @include breakpoint($break--sm, max) {
    flex-basis: 100px;
  }
}

/* Placeholders for region specific styles */
%formattedTextPrimary {
  .formatted-text {
    &--aligned-image {
      flex-direction: row;
    }

    &__image {
      &--left {
        @extend %formattedTextImageFlex;
        margin-right: $gutter--lg;
        order: 0;
      }

      &--right {
        @extend %formattedTextImageFlex;
        margin-left: $gutter--lg;
        order: 1;
      }

      @include breakpoint($break--sm, max) {
        &--left {
          margin-right: $gutter--sm;
        }
        &--right {
          margin-left: $gutter--sm;
        }
      }
    }
  }
}

%formattedTextSecondary {
  .formatted-text {
    &--aligned-image {
      flex-direction: column;
    }

    &__image {
      &--left,
      &--right {
        order: 1;
        margin-top: $gutter--lg;
      }

      @include breakpoint($break--sm, max) {
        &--left,
        &--right {
          margin-top: $gutter--sm;
        }
      }
    }

    &__cta {
      .button {
        margin-right: 0;
        flex: 1 0 100%;
        margin-bottom: $gutter--xsm;

        span {
          text-align: left;
          flex: 1;
          white-space: normal;
        }

        i {
          justify-self: flex-end;
        }
      }
    }
  }
}

.col-12 {
  .formatted-text__body {
    max-width: 700px;
  }

  @include breakpoint($break--md) {
    .formatted-text__image {
      flex: 1 1 auto;
      min-width: 200px;
    }
  }
}
