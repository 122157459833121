@use "sass:math";

.recent-transactions {
  &__filters {
    display: flex;
    justify-content: flex-end;

    @include breakpoint($break--sm, max) {
      width: 100%;
      flex-basis: 100%;
    }

    &-wrapper {
      display: flex;
      gap: $gutter--xsm;

      @include breakpoint($break--sm, max) {
        width: 100%;
        flex-wrap: wrap-reverse;
        justify-content: flex-end;
      }
    }

    .transactions__select {
      position: relative;

      select {
        padding-right: $gutter--xlg;

        @include breakpoint($break--sm, max) {
          font-size: $font-size--md;
          height: 40px;
          padding: $gutter--xsm ($gutter--xss + $icon-size--base) $gutter--xsm $gutter--xss;

          & + i {
            margin-bottom: $gutter--xsm;
            margin-right: $gutter--xss;
          }
        }
      }
    }
  }

  &.content-panel {
    margin-top: $gutter--lg * 2.375;

    @include breakpoint($break--sm, max) {
      margin-top: $gutter--xlg;
    }
  }

  &__table {
    th {
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 27.5%;
      }
      &:nth-child(3) {
        width: 27.5%;
      }
      &:last-child {
        width: auto;
      }
    }

    .table__body {
      .icon {
        &--circle {
          margin-right: $gutter--xsm;
        }

        &--car {
          background-color: $color-secondary--green;
        }

        &--gift {
          background-color: $color-secondary--pink;
        }
      }
    }

    i.icon {
      background-color: $color-tertiary--white;
    }

    .sticker-group {
      .sticker + .sticker {
        .sticker__label {
          padding-left: $gutter--xsm;
          font-size: $font-size--lg;
        }
      }
    }
  }
}

.transactions {
  &__filter {
    position: relative;
    margin-bottom: $gutter--xsm;

    air-search-bar-input {
      max-width: 100%;
      flex-basis: 100%;
      position: relative;
      opacity: 1;
      transform: none;
      margin: 0;
      padding: 0;
    }
  }

  &-filter__search,
  &-filter__items {
    @extend .product-filter__group;

    input[type="checkbox"] + label {
      margin-bottom: $gutter--xss;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &-filter__search,
  &-filter__items,
  &-filter__no-results {
    padding: $gutter--xss 0;
  }

  &-filter__items {
    max-height: 8.75rem;
    overflow-y: auto;
    margin-bottom: $gutter--md;

    /* Make scrollbar visible when needed - overrides default mobile behavior */
    @include breakpoint($break--sm, max) {
      &::-webkit-scrollbar {
        width: 0.2em;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c0c0c0;
        border-radius: 0.1em;
      }
    }
  }
}
