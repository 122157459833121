.header {
  width: 100%;
  background-color: $color-tertiary--white;
  padding-left: 0;
  padding-top: var(--ion-safe-area-top);
  z-index: z-index("header");

  &-container {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    color: $color-tertiary--white;
    position: relative;
    padding: $gutter--sm * 1.25;

    @include breakpoint($break--md, max) {
      padding: $gutter--xsm;
      flex-wrap: wrap;
    }

    @include breakpoint($break--sm, max) {
      padding: $gutter--xsm;
      flex-wrap: wrap;
    }
  }

  &-logo {
    display: flex;
    margin: (-$gutter--xsm) $gutter--xsm (-$gutter--xsm);
    margin-right: $gutter--md;

    @include breakpoint($break--sm, max) {
      margin: 0;
      position: absolute;
      display: block;
      left: 50%;
      transform: translateX(-50%);
    }

    div {
      width: 64px;
      height: 64px;

      @include breakpoint($break--md, max) {
        margin: 0 $gutter--sm 0 0;
        width: 48px;
        height: 48px;
      }

      @include breakpoint($break--sm, max) {
        margin: 0;
        width: 44px;
        height: 44px;
      }
    }
  }

  &-actions {
    display: flex;
    gap: $gutter--xsm;
    margin-left: $gutter--xsm;
  }

  &.loading {
    height: 88px;
    @include breakpoint($break--md, max) {
      height: 80px;
    }
    @include breakpoint($break--sm, max) {
      height: 64px;
    }
  }
}
