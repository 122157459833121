.breadcrumbs {
  &__list {
    display: flex;
    margin-top: $gutter--sm * 1.25;

    li {
      @extend .h5;
      color: $color-tertiary--white;
      margin-right: $gutter--xsm;
      display: flex;
      line-height: $icon-size--base;

      &:before {
        content: "";
        display: block;
        @extend .bg--100;
        flex-shrink: 0;
        @extend .icon--md;
        @extend .icon-ui-arrow--right--sm;
        background-color: $color-tertiary--white !important;
        margin-right: $gutter--xsm;
      }

      &:first-child {
        &:before {
          display: none;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        color: $color-tertiary--white;
        text-decoration: none;
      }

      @include breakpoint($break--sm, max) {
        &:not(.breadcrumbs__list-item--parent) {
          display: none;
        }
      }

      &.breadcrumbs__list-item--parent {
        @include breakpoint($break--sm, max) {
          &:before {
            display: block;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  // Don't display while page is loading
  @at-root .loading & {
    display: none;
  }

  // Own loading state, in case page is loaded, but component graphQL is still busy
  &.loading {
    li {
      @extend .loader--skeleton;
    }
  }
}
