@font-face {
  font-family: "Roboto";
  src: url("#{$font-path}Roboto-Regular.woff2") format("woff2");
  font-weight: $font-weight--normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("#{$font-path}Roboto-Bold.woff2") format("woff2");
  font-weight: $font-weight--bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("#{$font-path}Roboto-Italic.woff2") format("woff2");
  font-weight: $font-weight--normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("#{$font-path}Roboto-BoldItalic.woff2") format("woff2");
  font-weight: $font-weight--bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Mic 32 New Rounded Web";
  src: url("#{$font-path}Mic32NewRounded-Bold.woff2") format("woff2");
  font-weight: $font-weight--bold;
  font-style: normal;
  font-display: swap;
}
