.ticket-template-page {
  position: relative;
  width: 21cm;
  height: 29.6cm;
  margin: auto;
  overflow: hidden;
  margin-bottom: $gutter--md;
  line-height: $line-height--lg;

  h2 {
    @extend .h3;
  }

  &__header {
    width: 100%;
    background-color: transparent;
    padding: $gutter--sm * 1.25;
    padding-left: 0;
    z-index: z-index("header");
    margin-bottom: $gutter--lg;

    div {
      width: 64px;
      height: 64px;
    }
  }

  &__info {
    flex: 100%;

    .info__product {
      flex: 50%;

      &--details {
        div {
          display: flex;

          span:first-child {
            display: inline-block;
            flex: 0 0 150px;
          }
          span:last-child {
            word-wrap: break-word;
          }
        }
      }
    }

    .info__image {
      flex: 50%;
    }
  }

  &__content {
    flex-basis: 85%;
  }

  &__aside {
    pointer-events: none;
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: $gutter--xlg;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;

    .barcode,
    h2 {
      // Rotate barcode and move to re-center
      transform: rotate(-90deg) translate(50%, -50%);
      transform-origin: center right;
    }
  }

  &__footer {
    position: absolute;
    bottom: $gutter--md;
    width: 19.4cm;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background-color: transparent;
    padding: 0;

    .footer__details {
      flex: 85%;

      h2 {
        margin-bottom: 0;
      }
    }

    .footer__qr {
      flex: 15%;
    }

    .barcode > svg {
      margin-left: (-$gutter--xsm);
    }
  }

  .d-flex {
    display: flex;

    &--column {
      @extend .d-flex;
      flex-direction: column;
    }

    &--row {
      @extend .d-flex;
      flex-direction: row;
    }
  }

  &.content-panel:first-child {
    margin-top: 0;
  }
}

@media print {
  .container {
    max-width: none;
    margin: 0;
    padding: 0;
    -webkit-print-color-adjust: exact;
  }

  .ticket-template-page {
    break-after: always;
    margin-bottom: 0;
  }
}

@media screen {
  .page--ticket-template {
    .container {
      overflow: auto;
    }

    @include breakpoint($break--md, max) {
      .container {
        padding-left: 0;
        padding-right: 0;
      }

      .content-panel {
        margin-left: auto;
        margin-right: auto;
        padding: $gutter--xlg;
      }
    }

    @include breakpoint($break--sm, max) {
      .container {
        padding-left: 0;
        padding-right: 0;
      }

      .content-panel {
        margin-left: auto;
        margin-right: auto;
        padding: $gutter--xlg;
      }

      h1 {
        font-size: $font-size--xxxlg;
        margin-bottom: $gutter--lg;
      }

      h2 {
        font-size: $font-size--xlg;
        margin-bottom: $gutter--sm;
      }

      h3 {
        font-size: $font-size--xlg;
        margin-bottom: $gutter--sm;
      }

      p,
      .p,
      li {
        font-size: $font-size--md;
        line-height: $line-height--lg;
      }
    }
  }
}
