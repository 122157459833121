.partners {
  &__link {
    display: block;
    align-self: center;
    color: var(--color-primary);

    &:last-child {
      margin-right: 0;
    }
  }

  &.loading {
    justify-content: space-around;

    .link,
    img {
      width: 32px;
      height: 32px;
      @extend .loader--skeleton;
    }
  }
}

%partnersListHero {
  air-partners-list {
    @include breakpoint($break--sm, max) {
      flex: 1 0 100%;
      flex-wrap: wrap;
    }
  }

  .partners {
    background: rgba($color-tertiary--white, 0.7);
    border-bottom-left-radius: $border-radius--base;
    border-bottom-right-radius: $border-radius--base;
    padding: $gutter--xsm $gutter--sm;
    margin-bottom: $gutter--sm;

    @include breakpoint($break--sm, max) {
      border-radius: 0;
      padding: $gutter--xsm $gutter--md;
      margin-left: (-$gutter--xlg);
      margin-right: (-$gutter--xlg);
      height: 64px;
      @include breakpoint($break--xsm, max) {
        height: 48px;
      }
      ul {
        justify-content: space-evenly;
      }
    }

    &__link {
      margin-right: $gutter--sm;
      @include breakpoint($break--sm, max) {
        margin-right: 0;
      }

      img {
        max-width: 32px;
        @include breakpoint($break--sm, max) {
          max-width: $gutter--xlg;
          vertical-align: middle;
        }
        @include breakpoint($break--xsm, max) {
          max-width: 32px;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
