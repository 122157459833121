* {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  font-size: 16px;
}

body {
  font-family: $font-family--base;
  font-size: $font-size--md;
  font-weight: $font-weight--normal;
  line-height: $line-height--base;
  color: $color-tertiary--black;
}

input,
textarea,
select,
button {
  font-size: $font-size--md;
}

input:-webkit-autofill,
input:focus:-webkit-autofill {
  color: $color-tertiary--black;
  -webkit-text-fill-color: $color-tertiary--black;
}
input:-internal-autofill-selected {
  color: $color-tertiary--black;
  -webkit-text-fill-color: $color-tertiary--black;
}

#{$headings} {
  color: $color-primary--blue-darker;
  line-height: $line-height--md;
  padding: 0;
  margin: 0;
}

@each $heading in $headings {
  #{$heading},
  .#{$heading} {
    font-family: $font-family--mic32;
    font-weight: $font-weight--bold;
  }
}

h1,
.h1 {
  font-size: $font-size--xxxlg;
  margin-bottom: $gutter--lg;

  @include breakpoint($break--sm, max) {
    font-size: $font-size--xxlg * 0.875;
    margin-bottom: $gutter--md;
  }
}

h2,
.h2 {
  font-size: $font-size--xxlg;
  margin-bottom: $gutter--md;

  @include breakpoint($break--sm, max) {
    font-size: $font-size--xlg;
    margin-bottom: $gutter--sm;
  }
}

h3,
.h3 {
  font-size: $font-size--xlg;
  margin-bottom: $gutter--sm;

  @include breakpoint($break--sm, max) {
    font-size: $font-size--lg;
    margin-bottom: $gutter--xsm;
  }
}

h4,
.h4 {
  font-size: $font-size--lg;

  @include breakpoint($break--sm, max) {
    font-size: $font-size--ml;
  }
}

h5,
.h5 {
  font-size: $font-size--ml;

  @include breakpoint($break--sm, max) {
    font-size: $font-size--md;
  }
}

h6,
.h6 {
  font-size: $font-size--md;
}

strong {
  font-weight: $font-weight--bold;
}

u {
  text-decoration: underline;
}

em {
  font-style: italic;
}

p {
  white-space: pre-line;

  & + & {
    padding-top: $gutter--md;
  }
}

.text {
  @extend %textListStyling;

  &--sm {
    font-size: $font-size--sm;
  }
}

p,
.p,
li {
  font-size: $font-size--md;
  line-height: $line-height--lg;

  @include breakpoint($break--sm, max) {
    font-size: $font-size--sm;
  }

  a {
    text-decoration: underline;
    color: var(--color-primary);
  }
}

label {
  font-weight: $font-weight--normal;
  font-size: $font-size--base;

  &--sm {
    font-size: $font-size--sm;
  }
  @include breakpoint($break--sm, max) {
    font-size: $font-size--md;
  }
}

a,
.link {
  cursor: pointer;
  text-decoration: none;
  color: var(--color-primary-light);

  &:hover {
    text-decoration: underline;
  }

  &:active,
  &:focus {
  }
}

hr {
  height: 0;
  border-top: $border--default;
  margin: $gutter--lg 0 0;
}

small {
  font-size: $font-size--sm;

  p {
    font-size: $font-size--sm;
  }
}

.text-primary {
  color: $color-primary--blue;
}

.t {
  &--white {
    color: $color-tertiary--white;
  }
}
