.card-list--promotion {
  .card {
    @include breakpoint($break--sm, max) {
      margin: 0;
      margin-top: $gutter--sm;
    }

    &__stickers {
      @include breakpoint($break--sm, max) {
        position: absolute;
        top: calc($card-image-height - $sticker--md - $gutter--xsm);
        padding: 0;
        left: $gutter--xsm;
      }
    }
  }
}
