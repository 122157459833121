@media print {
  body {
    position: relative;
    overflow: visible;
  }

  air-root {
    position: relative;
  }

  .ion-page {
    position: relative !important;
    contain: none !important;
    overflow: visible;
  }

  ion-content {
    contain: none;

    &::part(scroll) {
      position: relative;
    }
  }
}
