.counter {
  background-color: #03c78d;
  border-radius: $gutter--md;
  padding: $gutter--xlg $gutter--lg;
  overflow: visible;
  position: relative;
  isolation: isolate;

  &__background {
    position: absolute;
    overflow: hidden;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    border-radius: $gutter--md;
    @include background-smile(rgba(255, 255, 255, 0.2), "top", 52%, "regular");
  }

  @include breakpoint($break--md, max) {
    padding: $gutter--lg $gutter--md;
  }

  &__label {
    text-align: center;
    color: $color-tertiary--white;
    margin-bottom: $gutter--sm;
  }

  &__values {
    position: relative;
    display: flex;
    justify-content: center;
    gap: $gutter--xss;

    @include breakpoint($break--md, max) {
      gap: $gutter--xsm;
    }

    &--8 {
      gap: $gutter--xsm;
      & > .counter__value {
        min-width: 58px;

        @include breakpoint($break--md, max) {
          min-width: 40px;
        }
        @include breakpoint($break--sm, max) {
          min-width: 30px;
          max-width: 48px;
        }
      }
    }

    &--7 > .counter__value {
      min-width: 58px;

      @include breakpoint($break--md, max) {
        min-width: 44px;
      }
      @include breakpoint($break--sm, max) {
        min-width: 30px;
        max-width: 48px;
      }
    }

    &--6 > .counter__value {
      min-width: 62px;

      @include breakpoint($break--md, max) {
        min-width: 52px;
      }
      @include breakpoint($break--sm, max) {
        min-width: 30px;
        max-width: 48px;
      }
    }
  }

  &__value {
    display: flex;
    flex-direction: column;
    gap: $gutter--xsm;
    background-color: #fff;
    border-radius: $gutter--xsm;
    padding: 0 5px;
    position: relative;
    overflow: hidden;
    align-items: center;

    @include breakpoint($break--sm, max) {
      padding: 0;
      flex: 1;
    }

    &--digit {
      display: flex;
      color: $color-primary--blue;

      font-family: $font-family--mic32;
      font-size: calc($font-size--xxxlg * 2);
      line-height: 96px;

      @include breakpoint($break--md, max) {
        line-height: 48px;
        font-size: $font-size--xxxlg;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__badge {
    position: absolute;
    height: fit-content;
    width: fit-content;

    background-color: $color-tertiary--white;
    font-family: $font-family--mic32;
    border-radius: $border-radius--base;
    padding: 0px $gutter--xsm;
    color: $color-secondary--green-dark;
    animation: fadeIn 2s;

    bottom: (-$gutter--sm);
    right: 0;
    transform: translateY(125%);
    @include breakpoint($break--md, max) {
      max-width: 100%;
      right: 0;
      padding: calc($gutter--xsm / 2) $gutter--xsm;
      transform: translateY(90%);
    }

    @include breakpoint($break--sm, max) {
      bottom: (-$gutter--sm);
    }

    &--inner {
      vertical-align: middle;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      @include breakpoint($break--md, min) {
        padding: calc($gutter--xsm / 2);
      }
      p {
        padding-top: 0px;
        line-height: 1;
      }
    }

    &--equals {
      float: left;
      font-size: $font-size--xlg;
      @include breakpoint($break--md, max) {
        font-size: $font-size--ml;
      }
    }

    &--number {
      float: left;
      font-size: $font-size--xxlg;
      margin-left: $gutter--xsm;
      @include breakpoint($break--md, max) {
        font-size: $font-size--xlg;
      }
    }

    &--text {
      float: left;
      font-size: $font-size--xlg;
      margin-left: $gutter--xsm;
      @include breakpoint($break--sm, max) {
        font-size: $font-size--ml;
      }
    }
  }
}
