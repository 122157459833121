.divider {
  &--wave {
    background-color: $color-primary--blue-light;
    background-image: url("#{$image-path}divider-wave.svg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 5.7vw;
    @extend .bg--100;

    @include breakpoint($break--sm, max) {
      height: 45px;
    }
  }
}
