.banner {
  @extend .h4;
  border-radius: $border-radius--base;
  padding: $gutter--xss;
  display: flex;
  gap: $gutter--xsm;
  align-items: center;

  &__message {
    flex: 1;
    vertical-align: middle;
  }

  &__action {
    display: flex;
    align-items: center;
    margin-left: $gutter--xsm;
  }

  &:hover {
    text-decoration: none;
  }

  @include breakpoint($break--sm, max) {
    position: static;
    transform: none;
    grid-column: span 4 / span 4;
  }

  &--pink {
    background: $color-secondary--pink;
    color: $color-secondary--pink-lighter;

    .banner {
      &__message {
        &--highlight {
          color: $color-tertiary--white;
        }
      }

      &__action {
        color: $color-tertiary--white;

        .icon {
          background-color: $color-tertiary--white;
        }
      }

      &__icon {
        background-color: $color-secondary--pink-lighter;
      }
    }
  }

  &--red-light {
    background: $color-secondary--red-lighter;
    color: $color-secondary--red;

    .icon {
      background-color: $color-secondary--red;
    }
  }
}
