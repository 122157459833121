@use "sass:math";

.theme--shell {
  .header {
    transition: background-color 0.25s $transition-easing--base;

    &-logo {
      position: relative;
      margin: 0 $gutter--sm 0 0;

      & + .header-logo {
        padding-left: $gutter--sm;
        border-left: solid 1px $color-primary--blue-light;
      }

      @include breakpoint($break--sm, max) {
        display: block;
        flex: initial;
        width: auto;

        div {
          margin: 0;
        }
      }
    }

    &-container {
      justify-content: flex-start;
      padding: calc($gutter--xsm * 1.5) calc($gutter--sm * 1.25);

      @include breakpoint($break--sm, max) {
        justify-content: space-between;
      }
    }

    .nav {
      .icon--hamburger {
        span {
          background-color: $color-tertiary--black;
        }
      }

      .link-list {
        &__links__item {
          &:hover {
            background-color: $color-tertiary--black-alpha-006;
          }
          a span {
            color: $color-tertiary--black-dark;
          }
        }
      }

      &--public {
        air-link-list {
          & > .link-list__group {
            & > * {
              @include breakpoint($break--sm, max) {
                margin-right: math.div($gutter--xsm, 2);
              }
            }
            & > .link-list__links {
              @media (hover: hover) {
                &:hover > .link-list__links__item {
                  background-color: $color-tertiary--black-alpha-006;
                }
              }
            }

            & > .link-list__section {
              &:last-child {
                margin-right: 0;
              }

              & > .link-list {
                @include breakpoint($break--sm, max) {
                  left: auto;
                  right: 0;
                }
              }

              & > .link-list__section__item {
                &:after {
                  @include breakpoint($break--sm, max) {
                    margin-left: (-$gutter--xss);
                  }
                }
              }

              &.section--open > .link-list__section__item {
                background-color: $color-tertiary--black-alpha-03;
              }

              @media (hover: hover) {
                &:hover > .link-list__section__item {
                  background-color: $color-tertiary--black-alpha-03;
                }
              }
            }
          }
        }
      }

      &--hamburger {
        air-link-list {
          & > .link-list {
            &__group {
              & > .link-list__links {
                @media (hover: hover) {
                  &:hover > .link-list__links__item {
                    background-color: $color-tertiary--black-alpha-006 !important;
                  }
                }
              }

              & > .link-list__section {
                @media (hover: hover) {
                  &:hover > .link-list__section__item {
                    background-color: $color-tertiary--black-alpha-006 !important;
                  }
                }
              }
            }
          }
        }

        .nav {
          &-container {
            margin-top: $gutter--lg;
            @include breakpoint($break--sm, max) {
              margin-top: $gutter--sm;
            }
          }
        }

        .link-list {
          &__links__item {
            &.active {
              background-color: transparent;
            }

            &:active {
              background-color: $color-tertiary--black-alpha-006;
            }
          }
        }

        .button {
          background-color: transparent;

          &--menu {
            box-shadow: none;
            border-radius: $border-radius--base;
            &:active {
              background-color: $color-tertiary--black-alpha-03;
            }

            @media (hover: hover) {
              &:hover {
                background-color: $color-tertiary--black-alpha-03;
              }
            }
          }
        }
      }
    }

    &--scrolling {
      background-color: $color-tertiary--white;
      transition:
        background-color 0.25s $transition-easing--base,
        padding 0.25s $transition-easing--base;
      color: $color-tertiary--black;
      box-shadow: var(--box-shadow--level1);

      .language-switcher {
        select {
          box-shadow: var(--box-shadow--level1);
        }
      }

      .nav {
        &--public {
          air-link-list {
            & > .link-list {
              &__group {
                & > .link-list__links {
                  & > .link-list__links__item {
                    a {
                      color: $color-tertiary--black;
                    }
                  }
                  @media (hover: hover) {
                    &:hover > .link-list__links__item {
                      background-color: $color-tertiary--black-alpha-006;
                    }
                  }
                }

                & > .link-list__section {
                  & > .link-list__section__item {
                    .link-list__section__title {
                      color: $color-tertiary--black;
                    }

                    &:after {
                      background-color: $color-tertiary--black;
                    }
                  }

                  @media (hover: hover) {
                    &:hover > .link-list__section__item {
                      background-color: $color-tertiary--black-alpha-006;
                    }
                  }

                  &.section--open > .link-list__section__item {
                    background-color: $color-tertiary--black-alpha-006;
                  }
                }
              }
            }
          }
        }

        &--hamburger {
          .button {
            &--menu {
              &:active {
                background-color: $color-tertiary--black-alpha-006;
              }

              @media (hover: hover) {
                &:hover {
                  background-color: $color-tertiary--black-alpha-006;
                }
              }

              .icon--hamburger {
                span {
                  background-color: $color-tertiary--black;
                }
              }
            }
          }
        }
      }
    }
  }

  #{$headings} {
    color: $color-grey--shell;
  }

  .breadcrumbs {
    &__list {
      li {
        opacity: 0.5;
        font-size: $font-size--md;
      }
    }
  }

  .hero {
    #{$headings} {
      color: $color-tertiary--white;
    }

    background-color: $color-secondary--shell;

    h1 {
      visibility: hidden;
      font-size: 0;
      margin-bottom: 0;

      &::before {
        content: "";
        background-image: $logo--miles-and-me--url;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: -1.5rem 0;
        display: block;
        height: 6rem;
        width: 15rem;
        visibility: visible;
      }
    }

    &__content {
      @extend .col-8;
      @extend .col-5--md;
      @extend .col-4--sm;

      @include breakpoint($break--sm, max) {
        margin-bottom: calc(55vw - 100px);
      }
    }

    .carousel__slides__slide {
      padding-top: $gutter--xlg;
    }

    &__image {
      clip-path: circle(closest-side at 50% 50%);
      width: 60%;
      left: unset;
      top: unset;
      right: 2%;
      bottom: -30%;
      height: 55vw;
      max-height: 100%;

      @include breakpoint($break--lg, max) {
        bottom: -20%;
      }

      @include breakpoint($break--md, max) {
        width: 52%;
        bottom: -10%;
      }

      @include breakpoint($break--sm, max) {
        width: min(95%, 500px);
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
      }

      @include breakpoint($break--xsm, max) {
        bottom: 0%;
      }

      &:before {
        background: none;
      }
    }

    &__body {
      color: $color-grey--shell;
    }
  }

  .formatted-text {
    color: $color-grey--shell;
  }

  .highlighted-card {
    &__panel {
      &-image {
        &:before {
          background-color: $color-secondary--shell;
        }
      }
    }
    &__text {
      color: $color-grey--shell;
    }
  }

  .footer {
    background-color: $color-tertiary--grey-darker;
    color: $color-tertiary--grey;

    a,
    h5 {
      color: $color-tertiary--grey;
    }

    &-container {
      background-color: $color-tertiary--grey-darker;
      padding-top: $gutter--xlg;
      margin-top: $gutter--xlg * 2;

      .divider--wave {
        display: none;
      }

      @include breakpoint($break--sm, max) {
        margin-top: $gutter--lg;
      }
    }

    &-nav {
      margin-top: 0;
      air-link-list {
        .link-list {
          &__section {
            &__title {
              color: $color-tertiary--grey;
            }
          }
        }
      }
    }

    &-end hr {
      border-top: 2px solid rgba($color-tertiary--grey, 0.2);
    }

    &-content {
      display: flex;

      > .footer__text {
        h5 {
          margin-bottom: $gutter--sm;
        }
        flex-basis: 100%;
        margin-bottom: $gutter--xlg;

        &--nav {
          flex-basis: 20%;
          margin-right: $gutter--lg * 3;
        }
      }

      @include breakpoint($break--sm, max) {
        flex-direction: column;
        > .footer__text {
          margin-right: 0;
          margin-bottom: $gutter--lg;
        }
      }
    }
  }

  .expandable-list {
    &__item__header {
      &.opened {
        h5 {
          color: var(--color-primary);
        }
      }
    }
    p {
      color: $color-grey--shell;
    }
  }

  .form {
    label {
      a {
        text-decoration: none;
      }
    }

    &-group--list {
      .option {
        border-color: $color-grey--shell;
        background-color: $color-tertiary--white;

        &.selected {
          background-color: $color-tertiary--grey-light;
        }
      }
    }

    &__checklist {
      div:not(.form__checklist--error):not(.form__checklist--valid) {
        i {
          background-color: $color-tertiary--black;
        }
      }
    }
  }

  .card {
    &--custom {
      background-color: $color-secondary--shell;
      .card__image {
        @include breakpoint($break--sm, min) {
          clip-path: circle(34% at 50% 50%);
        }
      }
    }
  }

  .sticker {
    &--miles {
      background-color: $color-primary--blue;
    }
  }

  .content {
    &-banner {
      background: $color-secondary--shell;
      &__panel-content {
        li,
        h1,
        h3 {
          color: $color-grey--shell;
        }
        ul li.styled::before {
          background-color: $color-grey--shell;
        }
      }
      &__panel-image {
        @include breakpoint($break--sm, max) {
          margin-top: (-$gutter--sm);
        }
        figure {
          height: 100%;
          @include breakpoint($break--sm, max) {
            margin-top: (-$gutter--xlg) * 2;
            height: min(calc(100vw - 4.5rem), 375px);
          }
          .cropped {
            clip-path: circle(closest-side at 50% 50%);
            left: 1%;
            bottom: -22%;
            top: unset;
            width: 100%;
            height: 100%;
            @include breakpoint($break--md, max) {
              bottom: 50%;
              transform: translateY(50%);
              width: unset;
              height: calc(100% - $gutter--lg);
              img {
                height: 100%;
                object-fit: cover;
              }
            }
            @include breakpoint($break--sm, max) {
              bottom: -22%;
              transform: unset;
              left: 0%;
              img {
                height: unset;
                object-fit: unset;
              }
            }
          }
        }
      }
    }
  }
}
