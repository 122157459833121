@mixin panel-overlap($overlap, $image-alignment: "right") {
  .highlighted-card {
    &__panel {
      &-content {
        flex: 0 0 calc(50% + #{$overlap});
        @if $image-alignment == "left" {
          padding-left: $overlap * 2;
        } @else {
          padding-right: $overlap * 2;
        }
      }

      &-item {
        flex: 0 0 50%;
        @if $image-alignment == "left" {
          order: -1;
          margin-right: -$overlap;
        } @else {
          order: 1;
          margin-left: -$overlap;
        }
      }
    }
  }
}

.highlighted-card {
  display: flex;
  flex-direction: row;

  &__text {
    h1 {
      margin-bottom: $gutter--xsm;
      @include breakpoint($break--sm, max) {
        margin-bottom: calc($gutter--xsm / 2);
      }
    }
    @extend %textListStyling;
  }

  &__panel {
    &-content {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: var(--box-shadow--level3);
      background-color: $color-tertiary--white;
      padding: $gutter--xlg $gutter--xxlg;
      border-radius: $border-radius--lg;

      @include breakpoint($break--md, max) {
        padding: $gutter--lg;
      }

      @include breakpoint($break--sm, max) {
        border-radius: 0 0 $border-radius--md $border-radius--md;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      position: relative;
      flex: 0 0 50%;
      align-self: center;
      margin: $gutter--md 0;
      background-color: transparent;
      height: 0;
      width: 100%;
      padding-bottom: 35%;
    }

    &-image {
      air-focal-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:before {
        content: "";
        position: absolute;
        background-color: $color-primary--blue;
        left: 0;
        top: $gutter--md;
        width: 100%;
        height: calc(100% - (#{$gutter--md} * 2));
        border-radius: $border-radius--lg;

        @include breakpoint($break--sm, max) {
          height: 100%;
          top: 0;
          border-radius: $border-radius--md $border-radius--md 0 0;
        }
      }

      img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: auto;
        max-height: 100%;
        object-fit: contain;
      }
    }

    &-counter {
      air-counter {
        position: absolute;
        top: 25%;
        left: 0;
      }
    }
  }

  &__cta {
    margin-top: $gutter--md;
  }

  &--item-right {
    @include breakpoint($break--sm, min) {
      @include panel-overlap($gutter--lg, "right");
    }

    @include breakpoint($break--md, min) {
      @include panel-overlap($gutter--lg * 1.5, "right");
    }
  }

  &--item-left {
    @include breakpoint($break--sm, min) {
      @include panel-overlap($gutter--lg, "left");
    }

    @include breakpoint($break--md, min) {
      @include panel-overlap($gutter--lg * 1.5, "left");
    }
  }

  @include breakpoint($break--sm, max) {
    flex-direction: column;
    flex-wrap: wrap;

    &__panel {
      &-content {
        order: 1;
      }

      &-item {
        order: 0;
        margin: 0;
        padding: 0 0 65% 0;
      }
    }

    &--item--right,
    &--item--left {
      flex: 0 0 100%;
      margin: 0;
      padding: 0;
    }
  }
}
