.content-block--card-details {
  margin-bottom: $gutter--xxlg;
  padding-bottom: 0;
  position: relative;

  @include breakpoint($break--md, max) {
    margin-bottom: $gutter--md;
  }
}

.card-details {
  @include background-smile(var(--color-primary), "top", calc(100% - 13.5rem), "steep");
  position: relative;
  overflow: hidden;
  isolation: isolate;

  @include breakpoint($break--sm, max) {
    @include background-smile(var(--color-primary), "top", 68%, "steep");
  }

  &.content-panel {
    margin-top: (-$gutter--lg);
    margin-bottom: (-$gutter--lg);

    @include breakpoint($break--md, max) {
      padding-top: $gutter--xlg;
      margin-bottom: 0;
      padding-bottom: $gutter--lg;
    }

    @include breakpoint($break--sm, max) {
      margin-top: $gutter--lg;
      margin-bottom: 0;
      padding-bottom: $gutter--xlg;
    }
  }

  &__banner {
    position: absolute;
    bottom: (-$gutter--md);
    right: 0;
    max-width: calc(100% - (2 * $gutter--xlg));
    z-index: 1;

    @include breakpoint($break--sm, max) {
      width: 100%;
      max-width: unset;
      left: 0;
    }
  }

  &--overview {
    display: flex;
    flex-direction: column;
    padding-top: $gutter--sm;
    margin-left: calc($gutter--xlg * 2);

    @include breakpoint($break--md, max) {
      padding-top: 0;
      margin-left: 0;
    }

    @include breakpoint($break--sm, max) {
      order: 1;
      background: $color-tertiary--white;
      margin: 0 (-$gutter--sm);
      padding: $gutter--sm;
    }
  }

  &--my-card {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;

    @include breakpoint($break--sm, max) {
      position: relative;
      display: block;
      order: -1;
    }

    .my-card {
      margin-bottom: 0;

      @include breakpoint($break--sm, max) {
        margin-bottom: $gutter--sm;
      }
    }
  }

  &__heading {
    background: var(--color-primary-lighter);
    width: fit-content;
    padding: calc($gutter--xsm/2) $gutter--xsm;
    border-radius: $border-radius--sm;
  }

  &__label {
    @extend h5;
    color: var(--color-primary);

    &--amount {
      @extend h1;
      color: var(--color-primary-darker);
      margin-bottom: $gutter--xsm;
    }
  }

  &__current-summary {
    display: flex;
    flex-direction: column;
    padding-bottom: $gutter--xsm;
    border-bottom: $border--default;

    h5 {
      margin-bottom: $gutter--xsm * 0.75;
    }

    @include breakpoint($break--md, max) {
      padding-right: 0;
      border-right: 0;
      border-bottom: $border--default;
      padding-bottom: $gutter--sm;
      flex-basis: 100%;
    }

    .card-details__label {
      white-space: break-spaces;
      width: 3rem;
      &--amount {
        font-size: $font-size--xxxlg * 1.5;
        margin-bottom: $gutter--xsm * 0.75;

        @include breakpoint($break--sm, max) {
          font-size: $font-size--xxxlg;
        }
      }
    }

    &.d--flex {
      justify-content: space-between;
    }
  }

  &__items {
    width: 100%;
    display: flex;
    gap: 0 $gutter--sm;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: $gutter--lg;

    @include breakpoint($break--md, max) {
      margin-right: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: $gutter--xsm;
  }

  &__add-to-wallet {
    text-align: right;
    position: relative;
    z-index: 1;

    a {
      transform: translate(3px, 50%);
      display: inline-block;
    }
    img {
      width: 108.76px;
      height: 32.68px;
    }
  }

  &__past-summary {
    margin-top: $font-size--xlg;
    display: flex;
    flex-wrap: wrap;
    gap: $gutter--xsm;

    @include breakpoint($break--md, max) {
      padding-left: 0;
      padding-top: $gutter--sm;

      h5 {
        margin-bottom: $gutter--xsm;
      }
    }
  }

  &.loading {
    h5,
    .card-details__label,
    .card-details__label--amount {
      @extend .loader--skeleton;
    }
  }

  &__spacer-mobile {
    @include breakpoint($break--sm, max) {
      margin-bottom: $gutter--lg;
    }
  }

  &__spacer-promotions {
    @include breakpoint($break--sm, max) {
      margin-top: (-$gutter--xlg);
      padding-top: $gutter--xlg;
    }
  }
}
