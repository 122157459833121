.maintenance {
  &-notification {
    background-color: $color-primary--blue-light;
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &__message {
      margin-top: 1px; // offset due to h6 rulings

      &,
      & > * {
        font-weight: $font-weight--bold;
        font-size: $font-size--md;
        color: $color-primary--blue;
      }

      padding: $gutter--xsm * 1.5 $gutter--xlg;
      color: $color-primary--blue;
      text-align: center;
    }

    &__close {
      position: absolute;
      right: $gutter--sm;
      margin-top: 2px;
      cursor: pointer;
    }
  }
}
