.product-filter {
  &__btn--expand {
    color: var(--color-primary);
    font-weight: $font-weight--bold;
    display: flex;
    align-items: center;
    line-height: $line-height--lg;

    i {
      margin-right: $gutter--xss;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;

    border-bottom: $border--default;
    padding-top: $gutter--xss;
    padding-bottom: $gutter--sm;

    &:first-child {
      padding-top: 0;
    }

    &.loading {
      .form-input,
      h5,
      input,
      label {
        @extend .loader--skeleton;
      }
    }

    .slider {
      margin-bottom: $gutter--sm;
    }
  }

  h5 {
    margin-bottom: $gutter--md;
  }

  .form-input {
    display: flex;
    margin-bottom: $gutter--sm;

    input[type="checkbox"],
    input[type="radio"] {
      &:checked + label {
        color: var(--color-primary);
        font-weight: $font-weight--bold;
      }
    }

    label {
      transition: color 0.4s $transition-easing--base;
      will-change: color;
      cursor: pointer;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
