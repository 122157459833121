.content--primary {
  .content-block--partner-logos {
    padding: $gutter--sm 0;
    border-top: 2px solid var(--color-primary-light);
    border-bottom: 2px solid var(--color-primary-light);
  }
}

%partnersListLogos {
  .partners-logos {
    padding: $gutter--lg 0;
    border-top: $border--default;
    border-bottom: $border--default;
    justify-content: center;

    &__link {
      margin-right: $gutter--lg;
      display: flex;
      justify-content: center;
      align-self: center;

      &:last-child {
        margin-right: 0;
      }

      @include breakpoint($break--sm, max) {
        margin-right: $gutter--xsm * 3.75;
      }
    }

    &__img {
      max-width: 64px;
      @include breakpoint($break--sm, max) {
        max-width: 64px;
      }
    }

    @include breakpoint($break--sm, max) {
      padding: $gutter--md 0;
    }

    &.loading {
      justify-content: space-around;

      .link,
      img {
        width: 32px;
        height: 32px;
        @extend .loader--skeleton;
      }
    }
  }
}
