.pagination .ngx-pagination {
  margin-top: $gutter--lg;
  display: flex;
  justify-content: center;

  .small-screen {
    display: none;
  }

  li {
    height: 48px;
    width: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-weight: $font-weight--bold;
    background-color: transparent;
    border-radius: $border-radius--base;

    a {
      color: var(--color-primary);
      text-decoration: none;

      &:hover {
        background-color: $color-tertiary--white;
      }
    }
  }

  .current {
    background-color: var(--color-primary);
    transition: background-color 0.3s $transition-easing--base;

    span {
      color: $color-tertiary--white;
    }
  }
}
