air-search-results-type {
  display: block;

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}
.results-type {
  @extend .content-block--product-overview;

  .product-list {
    margin-top: 1rem;
  }

  &__header {
    display: flex;
    flex-direction: row;
  }

  &__title {
    flex: 1;
    display: flex;
    align-items: center;
    gap: $gutter--xss;
    padding-right: $gutter--xsm;
    min-height: 48px;

    @include breakpoint($break--sm, max) {
      min-height: 40px;
    }
  }

  &__summary {
    line-height: $line-height--md;
  }

  @include breakpoint($break--sm, max) {
    .product-list {
      &__item {
        grid-column: span 4 / span 4;
        border-top: 0 none;
        padding: 0;

        .card {
          &--product {
            figure {
              width: 74px;
              height: 74px;
            }
          }

          &__text {
            padding-bottom: 0;
          }

          &__stickers {
            padding-top: 0;
          }
        }
      }
    }
  }
}
