.content-block--product-overview {
  .product-overview {
    &__header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .button--filter {
        justify-self: flex-end;
        @include breakpoint($break--sm, max) {
          margin-bottom: $gutter--sm;
        }
      }

      &.loading {
        #{$headings},
        span,
        p,
        input,
        label {
          @extend .loader--skeleton;
        }
      }
    }

    &__sorter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: $font-size--ml;
      flex-wrap: wrap;

      .form {
        width: 200px;
        &-group {
          margin: 0;
        }
      }

      &--label {
        margin-right: $gutter--sm;
        span {
          color: var(--color-primary);
          font-weight: $font-weight--bold;
        }
        line-height: $line-height--md;
      }

      select {
        font-size: $font-size--md;
        height: 40px;
        padding: $gutter--xsm ($gutter--xss + $icon-size--base) $gutter--xsm $gutter--xss;

        & + i {
          margin-bottom: $gutter--xsm;
          margin-right: $gutter--xss;
        }
      }

      @include breakpoint($break--md, max) {
        flex-basis: 100%;
        justify-content: flex-start;
      }

      @include breakpoint($break--sm, max) {
        &--label {
          margin-right: 0;
        }

        .form {
          margin-top: $gutter--sm;
          flex-basis: 100%;
        }
      }
    }
  }

  .product-list {
    @extend .grid;

    .card {
      border-bottom: 0;
    }

    @include breakpoint($break--md, min) {
      grid-template-columns: repeat(9, minmax(0px, 1fr));
    }

    &__item {
      grid-column: span 3 / span 3;
    }

    &--empty {
      padding: $gutter--md;

      h3 {
        margin-bottom: $gutter--xsm;
      }

      margin: 0 auto;
      text-align: center;
    }

    &--sm {
      @include breakpoint($break--md, min) {
        grid-template-columns: repeat(12, minmax(0px, 1fr));

        .card figure {
          padding-bottom: 100%;
        }
      }
    }
  }
}
