.text-list-feedback {
  display: grid;
  grid-template-rows: 1fr;
  margin-top: $gutter--md;
  opacity: 1;
  transition: all 0.75s $transition-easing--base;

  &--submitted {
    opacity: 0;
    grid-template-rows: 0fr;
    margin-top: 0;

    .text-list-feedback__collapse {
      overflow: hidden;
    }
  }

  &__header {
    font-weight: $font-weight--bold;
    margin-bottom: $gutter--xsm;
  }

  &__buttons {
    display: flex;
    gap: $gutter--xsm;
  }
}
