@import "receipt", "options", "feedback";

.payment {
  @extend .product-receipt;

  @at-root .modal--payment {
    @include breakpoint($break--lg, min) {
      max-width: 564px;
    }
  }

  .form {
    margin-top: 0;
  }

  .form-group {
    margin-top: $gutter--sm * 0.75;
    padding-top: $gutter--sm * 0.75;
    align-items: flex-start;

    .payment__cta {
      margin-top: $gutter--sm;
    }

    & > air-miles-sticker {
      flex: 1 0 0%;
      margin-top: 3px; // offset due to font difference in placement, align with sticker top
      @include breakpoint($break--sm, max) {
        margin-top: 2px;
      }
    }

    & > h4 {
      margin-top: 4px; // offset due to font difference in placement, align with sticker top
      @include breakpoint($break--sm, max) {
        margin-top: 4px;
      }
    }

    & > h5 {
      margin-top: 6px; // offset due to font difference in placement, align with sticker top
      @include breakpoint($break--sm, max) {
        margin-top: 7px;
      }
    }
  }

  .sticker {
    background: transparent;
    border: 0;
    color: var(--color-primary);
    margin-right: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    height: auto;

    &__counter,
    &__euro {
      font-size: $font-size--lg;
    }

    &--sm {
      margin-top: 0;

      &:first-child {
        padding-top: 0;
      }
    }

    &-group {
      align-items: flex-end;
      flex-direction: column;
    }
  }

  .total-block {
    display: flex;
    flex-wrap: nowrap;
    flex: 1 1 100%;
    justify-content: space-between;

    &__text {
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: $gutter--xsm;
      }

      span {
        font-size: $font-size--sm;
        line-height: $line-height--lg;
      }
    }
  }

  &__cta {
    background-color: var(--color-primary-lighter);

    & > * {
      flex: 1 0 100%;
      align-self: stretch;
    }

    .button {
      margin-top: $gutter--xsm;
    }
  }

  &__terms {
    border-top: 2px solid var(--color-primary-light);
    padding-top: $gutter--sm;
    margin-top: $gutter--sm * 1.5;

    @include breakpoint($break--sm, max) {
      padding-top: $gutter--xsm;
      margin-top: $gutter--xsm;
    }

    p {
      font-size: $font-size--sm;
      line-height: $line-height--lg;
    }

    input + label {
      &:before,
      &:after {
        top: 10px;
      }

      margin-bottom: $gutter--xsm;
      padding-left: $gutter--lg;

      @include breakpoint($break--sm, max) {
        margin-bottom: 0;
      }
    }
  }
}
