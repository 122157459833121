.login-modal {
  hr {
    margin-top: 0;
    margin-bottom: $gutter--sm;
  }

  &__partner-notice {
    margin-top: $gutter--md;
  }
}
