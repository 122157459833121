.logo {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &--air-miles {
    @extend .logo;
    background-image: $logo--url;
    //    @extend .bg--100;

    &--stamp {
      @extend .logo--air-miles;
      background-image: $logo--stamp--url;

      @include breakpoint($break--sm, max) {
        display: none;
      }
    }
  }

  &--shell {
    @extend .logo;
    background-image: $logo--shell--url;
  }
}

.pay-off {
  @extend .logo;
  background-image: $pay-off--url;
}
