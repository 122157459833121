.password-reset-modal {
  p {
    @include breakpoint($break--sm, max) {
      font-size: $font-size--base;
    }
  }

  .modal {
    &__header {
      margin-bottom: $gutter--md;

      @include breakpoint($break--sm, max) {
        margin-bottom: $gutter--xsm;
      }
    }

    &__button--close {
      align-self: flex-start;
    }

    &__footer {
      justify-content: space-between;
      display: flex;

      &--standalone {
        justify-content: flex-end;
      }
    }
  }

  .form-group {
    margin-top: 0;

    &--tabs {
      margin-top: $gutter--lg;
      margin-bottom: $gutter--sm;

      @include breakpoint($break--sm, max) {
        margin-top: $gutter--sm;
        margin-bottom: $gutter--xss;
      }
    }
  }
}
