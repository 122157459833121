.content-block--card-list {
  .card {
    @include breakpoint($break--sm, max) {
      margin-top: $gutter--sm;

      &:first-child {
        padding-top: 0;
      }
    }
  }
}

.card-list {
  position: relative;
  background: $color-tertiary--white;
  overflow: hidden;
  padding: $gutter--md $gutter--xlg $gutter--lg;
  border-radius: $border-radius--lg;
  isolation: isolate;

  @include breakpoint($break--md, max) {
    padding: $gutter--md $gutter--lg $gutter--lg;
  }

  &--header {
    display: flex;
    justify-content: space-between;
  }

  .card {
    background-color: transparent;

    &:hover {
      background: transparent;
      cursor: unset;
      box-shadow: none;
    }

    &--overlap {
      position: relative;
      margin-bottom: calc($gutter--sm + 0.25rem);

      @include breakpoint($break--sm, min) {
        margin-bottom: $gutter--md;
      }
    }

    &__image {
      border-radius: $border-radius--md;
      overflow: hidden;
      figure {
        @include breakpoint($break--md, max) {
          width: 100%;
          height: $card-image-height;
        }
      }
    }
    &__cta {
      margin: 0 0 $gutter--xsm;
      padding: 0 $gutter--sm;
      &--overlap {
        position: absolute;
        margin: 0;
        padding: 0;
        bottom: calc(#{-$gutter--sm} + -0.25rem);
        right: $gutter--sm;
        @include breakpoint($break--sm, min) {
          bottom: (-$gutter--md);
        }
      }
    }
    &__step {
      @include background-smile(var(--color-primary-lighter), "top", 52%, "regular");
      position: absolute;
      background: $color-tertiary--white;
      overflow: hidden;
      left: $gutter--sm;
      top: $gutter--sm;
      height: $sticker--md;
      width: $sticker--md;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      isolation: isolate;
      p {
        z-index: 1;
        margin: 0;
        color: var(--color-primary);
      }
    }

    @include breakpoint($break--sm, max) {
      display: block;
    }
  }
}

.content-section:not(.content--highlighted) {
  .card-list:not(.card-list--rewards) {
    padding: 0;
    border-radius: 0;

    .card {
      &:hover {
        background: transparent;
        box-shadow: none;
      }
    }
    // &.card-list--overlap {
    //   padding-bottom: $gutter--xlg;
    // }
  }
}
