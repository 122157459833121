air-personal-hero {
  position: relative;
  z-index: z-index("navigation");
}

.hero--personal {
  z-index: z-index("navigation");

  .container {
    min-height: 280px;
    margin-bottom: 4rem;
  }

  .logo--air-miles--stamp {
    position: absolute;
    top: (-$gutter--xlg) * 2;
    right: 0;
    width: 608px;
    height: 608px;
    z-index: -1;
  }

  .hero__content {
    min-height: auto;
    padding-bottom: 0;
    justify-content: start;
  }

  @include breakpoint($break--md, max) {
    .hero__header {
      flex-wrap: wrap;
    }

    air-partners-list {
      order: -1;
    }

    .hero__content {
      min-height: calc(280px - $gutter--xlg);
    }
  }

  @include breakpoint($break--sm, max) {
    .hero__header {
      margin-bottom: (-$gutter--sm);
    }

    .container {
      min-height: 0;
    }

    .logo--air-miles-stamp {
      max-width: 530px;
      max-height: 530px;
    }

    .hero__content {
      min-height: auto;
      margin-top: (-$gutter--sm);
    }

    .hero__title {
      display: none;
    }
  }
}
