.style-guide {
  padding-top: $gutter--lg;

  &-block {
    margin-bottom: $gutter--lg;

    &__label {
      display: block;
      font-weight: $font-weight--bold;
      text-decoration: underline;
    }
  }

  air-breadcrumbs {
    background-color: var(--color-primary);
  }

  .grid-demo {
    [class^="col-"],
    [class*=" col-"] {
      background-color: rgba(var(--color-secondary), 0.2);
      padding: $gutter--xsm;
      margin-bottom: 5px;
      border: 1px solid black;
      text-align: center;
      font-weight: $font-weight--bold;
    }
  }

  .shadow {
    width: 100px;
    height: 100px;
    display: inline-flex;
    margin-right: $gutter--sm;

    &--01 {
      box-shadow: var(--box-shadow--level1);
    }

    &--02 {
      box-shadow: var(--box-shadow--level2);
    }

    &--03 {
      box-shadow: var(--box-shadow--level3);
    }
  }

  .icon {
    display: inline-block;
  }
}
