.content-block + .content-block--expirations {
  margin-top: $gutter--lg * 2;
}

.expirations {
  &__description {
    @extend %textListStyling;
    margin-bottom: $gutter--md;
  }
}
