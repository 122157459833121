%loadingAnimationPulse {
  animation: loader-skeleton-pulse 3s $transition-easing--base infinite;
}

%loadingAnimationGradient {
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.76) 13.56%,
    rgba(255, 255, 255, 0) 51.15%
  ) !important;
  background-size: 200% 100%;
  animation: loader-skeleton-gradient 3s $transition-easing--base infinite;
}

.loader {
  &--spinner {
    @extend .icon;
    position: relative;
    background-color: transparent;

    &:before,
    &:after {
      content: "";
      width: 75%;
      height: 75%;
      border-radius: 50%;
      border: 2px currentColor solid;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    &:before {
      opacity: 0.2;
    }

    &:after {
      border-color: rgba(0, 0, 0, 0);
      border-top-color: currentColor;
      animation: loader-spinner 0.7s cubic-bezier(0, 0, 0, 0.5) infinite forwards;
    }
  }

  &--skeleton {
    background-color: var(--color-primary-light);
    background-image: none;
    -webkit-mask-image: none !important;
    color: transparent;
    border: none;
    outline: none;
    mix-blend-mode: multiply;
    transform: none;
    content: none;
    box-shadow: none;
    cursor: initial;
    @extend %loadingAnimationPulse;

    * {
      color: transparent;
    }

    &:after,
    &:before {
      content: none;
    }

    &::selection,
    &::-moz-selection {
      color: transparent;
      background-color: var(--color-primary-light);
    }

    @media (hover: hover) {
      &:hover {
        border: none;
        box-shadow: none;
        cursor: default;
        transform: none;
        content: none;
      }
    }
  }
}

@keyframes loader-spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

@keyframes loader-skeleton-pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes loader-skeleton-gradient {
  0% {
    background-position: 20% 100%;
  }
  50% {
    background-position: -70% 100%;
  }
  100% {
    background-position: 20% 100%;
  }
}
