.content-block--share-account {
  .share-account__description,
  h3 {
    margin-bottom: $gutter--lg;

    @include breakpoint($break--sm, max) {
      margin-bottom: $gutter--md;
    }
  }
}
.share-account {
  &--top-block + &--bottom-block {
    padding-top: $gutter--lg;
    margin-top: $gutter--lg;
    border-top: $border--default;

    @include breakpoint($break--sm, max) {
      padding-top: $gutter--md;
      margin-top: $gutter--md;
    }
  }

  .loading {
    #{$headings},
    span,
    p,
    i {
      @extend .loader--skeleton;
    }
  }
}
