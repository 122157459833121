@use "sass:math";
@import "../mixins/background-smile";

%textListStyling {
  ol,
  ul {
    margin-top: $gutter--md;
    margin-bottom: $gutter--md;

    li {
      line-height: $line-height--lg;

      &.styled {
        list-style-type: none;

        &:before {
          margin-right: $gutter--sm;
        }
      }
    }

    @include breakpoint($break--sm, max) {
      margin-top: $gutter--sm;
      margin-bottom: $gutter--sm;

      li {
        &.styled {
          &:before {
            margin-right: $gutter--xsm;
          }
        }
      }
    }
  }

  ol {
    position: relative;
    margin-left: 0;
    padding-left: $gutter--md;
    list-style: decimal;

    li.styled {
      display: block;
      margin-bottom: $gutter--md;
      margin-left: $gutter--sm;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        @extend .h1;
        margin-bottom: 0;
        line-height: $line-height--md;
        color: var(--color-primary);
        counter-increment: number;
        content: counter(number) "\a0";
        position: absolute;
        left: 0;
      }
    }
  }

  ul {
    list-style-type: disc;
    padding-left: $gutter--xsm;

    li {
      margin-left: $gutter--sm;
      &.styled {
        margin-left: (-$gutter--xsm);
        margin-top: math.div($gutter--xsm, 2);
        list-style-type: none;

        &:before {
          content: "";
          display: inline-block;
          width: $icon-size--base;
          height: $icon-size--base;
          vertical-align: middle;

          @supports (mask: url("#{$icon-path}icon-ui-checkmark.svg")) {
            mask-image: url("#{$icon-path}icon-ui-checkmark.svg");
            background-color: var(--color-primary);
          }
        }

        @include breakpoint($break--sm, max) {
          &:before {
            @supports (mask: url("#{$icon-path}icon-ui-checkmark--sm.svg")) {
              mask-image: url("#{$icon-path}icon-ui-checkmark--sm.svg");
            }
          }
        }

        &:first-child {
          margin-top: (-$gutter--xsm);
        }
      }
    }
  }
}

.content {
  ul,
  ol {
    counter-reset: number;
  }

  img {
    width: 100%;
    height: auto;
  }

  &-section:first-child {
    margin-top: -($gutter--lg) * 2;
  }

  /** Panels **/
  &-panel {
    background-color: $color-tertiary--white;
    box-shadow: var(--box-shadow--level3);
    margin-left: (-$gutter--xlg);
    margin-right: (-$gutter--xlg);
    padding: $gutter--xlg;

    @include breakpoint($break--lg, min) {
      border-radius: $border-radius--lg;
    }

    @include breakpoint($break--md, max) {
      margin-top: 0;
      margin-left: (-$gutter--lg);
      margin-right: (-$gutter--lg);
      padding: $gutter--lg;
    }

    @include breakpoint($break--sm, max) {
      margin-left: (-$gutter--md);
      margin-right: (-$gutter--md);
      padding: $gutter--sm $gutter--md;
    }

    @at-root .loading & {
      min-height: 400px;
    }
  }

  /** Region specific styling **/
  &--primary {
    @extend %formattedTextPrimary;
  }

  &--secondary {
    @extend %formattedTextSecondary;

    @include breakpoint($break--md, max) {
      margin-top: $gutter--lg;
    }

    @include breakpoint($break--sm, max) {
      margin-top: $gutter--lg;
    }
  }

  /** Content blocks **/
  &-block {
    margin-bottom: $gutter--lg;

    @extend %partnersListLogos;

    &:last-child {
      margin-bottom: 0;
    }

    &--card-details {
      padding-top: 0 !important;
      margin-top: -4.5rem;
    }

    /** Implement loaders for specific content-blocks **/
    &--export-personal-data,
    &--expirations,
    &--recent-transactions,
    &--highlighted-products,
    &--highlighted-promotions {
      .loading {
        #{$headings},
        p,
        span,
        i {
          @extend .loader--skeleton;
        }
      }
    }

    @include breakpoint($break--sm, max) {
      &--product-overview,
      &--highlighted-products {
        .product-list {
          .card {
            &__text {
              padding-bottom: 0;
            }

            &__stickers {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  &__text {
    margin-bottom: $gutter--xlg;
    div + p {
      padding-top: $gutter--md;
    }
  }

  &--highlighted {
    .content-block {
      margin-top: $gutter--xxlg;
      margin-bottom: 0;

      @include breakpoint($break--md, max) {
        margin-top: $gutter--xlg;
      }

      @include breakpoint($break--sm, max) {
        margin-top: $gutter--lg;
      }

      //Highlighted card
      &--formatted-text,
      &--highlighted-products,
      &--organization-overview,
      &--partner-logos,
      &--referral-rewards {
        background: $color-tertiary--white;
        border-radius: $border-radius--md;
        padding: $gutter--md $gutter--xlg $gutter--lg;

        @include breakpoint($break--md, max) {
          padding: $gutter--md $gutter--lg $gutter--lg;
        }
      }

      //Highlighted card smile
      &--formatted-text,
      &--highlighted-promotions,
      &--highlighted-products,
      &--organization-overview,
      &--partner-logos,
      &--referral-rewards,
      &--card-list {
        position: relative;
        overflow: hidden;
        isolation: isolate;

        air-title {
          @include background-smile($color-primary--blue-lighter, "top", calc(100% - 5.3rem), "gentle");
          @include breakpoint($break--sm, max) {
            @include background-smile($color-primary--blue-lighter, "top", calc(100% - $gutter--xxlg), "gentle");
          }

          .title {
            position: relative;
          }
        }
      }

      &--highlighted-products {
        @include breakpoint($break--sm, max) {
          padding: $gutter--sm;
        }
      }

      &--referral-rewards {
        air-title {
          @include background-smile($color-primary--blue-lighter, "top", calc(100% - 5.3rem - $gutter--md), "gentle");
          @include breakpoint($break--sm, max) {
            @include background-smile($color-primary--blue-lighter, "top", calc(100% - $gutter--xxlg - $gutter--md), "gentle");
          }
        }
      }

      &--formatted-text {
        .formatted-text {
          &__body {
            max-width: 700px;
          }
        }
      }
    }
  }
}
