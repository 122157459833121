carousel {
  position: relative;
  display: block;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__inner {
    position: relative;
    height: 0;
    padding-bottom: 62%;
  }

  &__slides {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &__slide {
      flex: 0 0 100%;

      figure {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: none;
        }
      }
    }
  }

  &__controls {
    width: 100%;
  }

  &__control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: $border-radius--base;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-tertiary--black-alpha-06;

    i {
      background-color: $color-tertiary--white;
    }

    &--prev {
      left: $gutter--xsm;
    }

    &--next {
      right: $gutter--xsm;
    }
  }

  &__indicators {
    position: absolute;
    left: 50%;
    bottom: $gutter--xsm;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);
    cursor: pointer;

    li {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: $gutter--xsm;
      border: 1px solid $color-tertiary--white;
      transition: background 0.25s $transition-easing--base;
      will-change: opacity;

      &.active,
      &:hover {
        background-color: $color-tertiary--white;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__thumbs {
    margin-top: $gutter--xsm;
    overflow: auto;

    ul {
      display: flex;
      flex-wrap: nowrap;
    }

    li {
      flex: 0 0 100px;
      height: 64px;
      margin-right: $gutter--xsm * 0.5;
      opacity: 0.3;
      transition: opacity 0.4s $transition-easing--base;
      will-change: opacity;
      cursor: pointer;

      @include breakpoint($break--md, max) {
        flex: 0 0 64px;
        height: 40px;
      }

      &.active {
        opacity: 1;
        border: $border--dark;
      }

      * {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }
    }
  }

  &:has(.carousel__thumbs) + .carousel__controls {
    background: green;

    .carousel__control {
      transform: translateY(calc(-50% - 36px));

      @include breakpoint($break--md, max) {
        transform: translateY(calc(-50% - 20px));
      }
    }
  }

  &--list {
    width: calc(100% - $gutter--xlg * 2);
    left: $gutter--xlg;
    .carousel {
      &__inner {
        height: unset;
        padding-bottom: 0;
      }

      &__slides {
        padding: $gutter--lg 0 $gutter--xlg;
        position: relative;

        &__slide {
          flex: 0 0 20%;

          .container {
            padding: 0 $gutter--sm;
          }

          @include breakpoint($break--sm, max) {
            flex: 0 0 calc(100% / 3);

            .container {
              padding: 0 $gutter--xsm;
            }
          }

          @include breakpoint($break--xsm, max) {
            flex: 0 0 100%;

            .container {
              padding: 0 $gutter--md;
            }
          }
        }
      }

      &__control {
        transform: translateY(calc(-50% - $gutter--md/2));

        &--next {
          right: 0;
        }

        &--prev {
          left: 0;
        }
      }
      &__indicators {
        li {
          border-color: var(--color-primary);

          &.active {
            background-color: var(--color-primary);
          }
        }
      }
    }
  }
}
