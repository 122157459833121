@use "sass:math";

.badge {
  font-family: $font-family--mic32;
  color: $color-tertiary--white;
  font-size: $font-size--sm;
  background-color: var(--color-cta);
  border-radius: 9px;
  padding: $gutter--md * 0.125 math.div($gutter--xsm, 2);
  line-height: $line-height--base;

  &--grey {
    background-color: $color-tertiary--grey-dark;
  }

  &--green {
    background-color: $color-secondary--green;
  }

  .icon {
    background: $color-tertiary--white;
    margin: (-$gutter--xsm) math.div(-$gutter--xsm, 2);
  }

  &--button {
    display: flex;
    align-items: center;
    padding-left: $gutter--xsm;

    // Set margin to override Safari's default style sheet
    margin: 0;
  }
}
