air-search-results-box {
  display: none;
  position: absolute;
  width: 100%;
  z-index: z-index("search");
  overflow: auto;
  height: 100%;

  @include breakpoint($break--sm, max) {
    padding-bottom: 50px;
  }

  .backdrop {
    background: $color-tertiary--black-alpha-06;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 1;
  }

  .container {
    padding-left: 6rem;
    padding-right: 6rem;
    z-index: 2;
    transform: translate3d(0, 0, 0);
    pointer-events: none;

    // Resolves z-index issue on IOS: https://stackoverflow.com/a/38505679
    -webkit-transform: translateZ(0px);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000;

    .content-panel {
      border-radius: 0 0 $border-radius--base $border-radius--base;
      pointer-events: all;
    }

    @include breakpoint($break--sm, max) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.searchActive air-search-results-box {
  display: block;
}
.button--search {
  .icon {
    background-color: var(--color-primary);
  }
}
