@import "../../mixins/background-smile";

.card--product,
.card--custom {
  @extend .card;
  margin-right: 0;

  &--large {
    grid-template-rows: 280px;
    overflow: hidden;

    .card__image {
      width: 100%;
      height: 100%;
      padding: 0;

      position: absolute;
      bottom: 0;
      left: 0;
      overflow: hidden;
      isolation: isolate;

      grid-row: 1 / span 2;
      border-radius: $border-radius--md;

      figure {
        width: 100%;
        height: 100%;
      }

      img {
        border-radius: 0;
      }

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }

    .card__stickers {
      grid-row: 1/3;
      justify-self: flex-end;
      padding: $gutter--md;

      @include breakpoint($break--sm, max) {
        padding: $gutter--sm;
        justify-self: flex-start;
        margin-bottom: calc($gutter--lg * 2);
      }
    }

    .card__icon {
      background-color: $color-tertiary--black-alpha-06;

      i {
        background-color: $color-tertiary--white;
      }

      @include breakpoint($break--sm, max) {
        top: $gutter--xsm * 1.5;
        right: $gutter--xsm * 1.5;
      }
    }

    .card__text {
      min-height: 180px;
      color: $color-tertiary--white;
      grid-row: 1/3;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: $gutter--md;

      @include breakpoint($break--lg, min) {
        padding-top: $gutter--xxlg * 3;
      }

      @include breakpoint($break--md, min) {
        margin-right: calc($gutter--lg * 4);
      }

      @include breakpoint($break--md, max) {
        grid-row: 2;
      }

      @include breakpoint($break--sm, max) {
        min-height: 180px;
        margin-bottom: $gutter--xsm;
        padding: $gutter--sm;
      }
    }
    .card__title {
      z-index: 1;
      position: relative;
      @include background-smile($color-primary--blue, "bottom", -80%, "gentle", -1);
    }
    .card__description {
      z-index: 1;
    }
  }
}

.card--product {
  padding-bottom: $gutter--xss;
}

air-skeleton-card {
  .card__image {
    figure {
      border-radius: $gutter--sm $gutter--sm 0 0;
    }
  }

  .card--product--large {
    .card__image {
      overflow: inherit;

      figure {
        border-radius: $gutter--sm;
      }
    }
  }
}
