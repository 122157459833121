@mixin grid {
  $grid-breakpoints: (
    lg: $break--lg,
    md: $break--md,
    sm: $break--sm
  ) !default;

  $grid-columns: (
    lg: 12,
    md: 6,
    sm: 4
  ) !default;

  $grid-gutter: (
    lg: 32px,
    md: 32px,
    sm: 16px
  ) !default;

  $grid-container-gutter: (
    lg: 48px,
    md: 32px,
    sm: 16px
  ) !default;

  .container {
    padding-left: map-get($grid-container-gutter, lg);
    padding-right: map-get($grid-container-gutter, lg);
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(map-get($grid-columns, lg), [col] 1fr);
    grid-column-gap: map-get($grid-gutter, lg);
    grid-row-gap: map-get($grid-gutter, lg);
    grid-auto-flow: dense;

    // Create non-breakpoint specific columns and column offsets
    @for $i from 0 through map-get($grid-columns, lg) {
      .col-start-#{$i} {
        grid-column-start: #{$i} !important;
      }
      .col-end-#{$i} {
        grid-column-end: #{$i + 1} !important;
      }
      .col-#{$i} {
        grid-column: span #{$i}/ span #{$i};
      }
    }

    &--no-spacing {
      grid-row-gap: 0;
    }
  }

  // Create breakpoint specific columns
  @each $breakpoint-name in map-keys($grid-breakpoints) {
    // Get each key's value
    $breakpoint-width: map-get($grid-breakpoints, $breakpoint-name);

    // Create media query for each breakpoint
    @include breakpoint($breakpoint-width, max) {
      .container {
        padding-left: map-get($grid-container-gutter, $breakpoint-name);
        padding-right: map-get($grid-container-gutter, $breakpoint-name);
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(map-get($grid-columns, $breakpoint-name), [col] minmax(0px, 1fr));
        grid-column-gap: map-get($grid-gutter, $breakpoint-name);
        grid-row-gap: map-get($grid-gutter, $breakpoint-name);
        grid-auto-flow: dense;

        // Create columns and column offsets for each size in this breakpoint
        @for $i from 0 through map-get($grid-columns, $breakpoint-name) {
          .col-start-#{$i}--#{$breakpoint-name} {
            grid-column-start: #{$i} !important;
          }
          .col-end-#{$i}--#{$breakpoint-name} {
            grid-column-end: #{$i + 1} !important;
          }
          .col-#{$i}--#{$breakpoint-name} {
            grid-column: span #{$i} / span #{$i};
          }
        }

        &--no-spacing {
          grid-row-gap: 0;
        }
      }
    }
  }

  @at-root .show-grid {
    .grid-debug {
      position: fixed;
      width: 100vw;
      height: 100vh;
      pointer-events: none;

      .container {
        position: relative;
        height: 100%;

        &:after {
          content: "";
          position: absolute;
          display: block;
          height: 100%;
          top: 0;
          background-color: rgba(255, 0, 0, 0.05);
          z-index: 0;
        }
        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba(0, 255, 0, 0.05);
        }

        .grid {
          height: 100%;
        }
      }

      [class^="col-"],
      [class*=" col-"] {
        height: 100%;
        &:after {
          content: "";
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 0, 0, 0.05);
          z-index: 1;
        }
      }
    }
  }
}
@include grid;
