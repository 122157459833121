@use "sass:math";

// cSpell:disable
$icons: "social-twitter", "social-facebook", "social-instagram", "social-linkedin", "social-youtube", "ui-alert", "ui-arrow--down",
  "ui-arrow--left--sm", "ui-arrow--right--sm", "ui-arrow--left", "ui-arrow--right", "ui-arrow--up", "ui-checkmark--sm", "ui-close--sm",
  "ui-close", "ui-filter", "ui-loader", "ui-login", "ui-logout", "ui-mail", "ui-minus", "ui-nav-1", "ui-nav-cards", "ui-nav-dashboard",
  "ui-nav-inwisselen", "ui-nav-more", "ui-nav-sparen", "ui-nav", "ui-phone", "ui-plus", "ui-search", "ui-eye-open", "ui-eye-closed",
  "category-abonnementen", "category-all", "category-categorieen", "category-goededoelen", "category-kassakorting", "category-producten",
  "category-uitjes", "category-uitgelicht", "category-cadeaukaarten", "ui-car", "ui-gift", "ui-sort--ascend", "ui-sort--descend",
  "ui-checkmark", "ui-bubble", "ui-trash", "ui-upvote", "ui-downvote", "ui-dot";
/* cSpell:enable */

.icon {
  display: inline-flex;
  @extend .bg--100;
  flex-shrink: 0;
  width: $icon-size--base;
  height: $icon-size--base;

  @supports (mask: url("something.svg")) {
    background-color: var(--color-primary);
    //    color: var(--color-primary);
  }

  @each $icon in $icons {
    &-#{$icon} {
      background-image: url("#{$icon-path}icon-#{$icon}.svg");
      background-size: 100% 100%;

      @supports (mask: url("#{$icon-path}icon-#{$icon}.svg")) {
        mask-image: url("#{$icon-path}icon-#{$icon}.svg");
        -webkit-mask-image: url("#{$icon-path}icon-#{$icon}.svg");
        -ms-mask-image: url("#{$icon-path}icon-#{$icon}.svg");
        background-image: none;
        mask-size: cover;
      }
    }
  }

  &--circle {
    // Container for circle background
    border-radius: 100%;
    width: $icon-size--md;
    height: $icon-size--md;
    color: $color-tertiary--white;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--lg {
    width: $icon-size--lg;
    height: $icon-size--lg;
  }
  &--md {
    width: $icon-size--base;
    height: $icon-size--base;
  }
  &--sm {
    width: $icon-size--sm;
    height: $icon-size--sm;
  }

  &--hamburger {
    width: $icon-size--base;
    height: calc($icon-size--base / 1.2);
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s $transition-easing--base;

    span {
      width: 100%;
      height: 3px;
      position: absolute;
      left: 0;
      background-color: $color-tertiary--white;
      transition: all 0.3s $transition-easing--base;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: math.div($gutter--sm, 2);
      }

      &:nth-child(3) {
        top: $gutter--sm;
      }
    }

    &--expanded {
      transform: rotate(-45deg);
      span {
        &:nth-child(1) {
          top: math.div($gutter--sm, 2);
        }

        &:nth-child(2) {
          transform: rotate(-90deg);
          top: math.div($gutter--sm, 2);
        }

        &:nth-child(3) {
          top: math.div($gutter--sm, 2);
        }
      }
    }
  }
}
