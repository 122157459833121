// Assets Paths
$asset-path: "/assets/";
$image-path: "#{$asset-path}images/";
$icon-path: "#{$asset-path}images/icon/";
$font-path: "#{$asset-path}fonts/";

// Breakpoints
$break--xsm: 325px;
$break--sm: 768px;
$break--md: 1024px;
$break--lg: 1160px;
$break--xlg: 1200px;
$break--xxlg: 1376px;

// Border radius
$border-radius--base: 8px;
$border-radius--sm: 4px;
$border-radius--smd: 12px;
$border-radius--md: 16px;
$border-radius--lg: 24px;

// Box shadows
$box-shadow--level1: 0 2px 4px rgb(0 125 195 / 10%);
$box-shadow--level2: 0 8px 12px rgb(0 125 195 / 10%);
$box-shadow--level3: 0 20px 40px rgb(0 125 195 / 10%);
$box-shadow--shell--level1: 0 2px 4px rgb(153 153 153 / 10%);
$box-shadow--shell--level2: 0 8px 12px rgb(153 153 153 / 10%);
$box-shadow--shell--level3: 0 20px 40px rgb(153 153 153 / 10%);

// Airmiles primary colors
$color-primary--blue: #007ce1;
$color-primary--blue-dark: #0171b0;
$color-primary--blue-darker: #035891;
$color-primary--blue-light: #cce5f9;
$color-primary--blue-lighter: #f0f6f9;

$color-secondary--pink: #eb5496;
$color-secondary--pink-light: #f5b3c4;
$color-secondary--pink-lighter: #ffedf2;
$color-secondary--pink-darker: #bc4378;
$color-secondary--orange: #f18700;
$color-secondary--orange-dark: #dd7300;
$color-secondary--yellow: #fbce07;
$color-secondary--green: #03c78d;
$color-secondary--green-dark: #1bae83;
$color-secondary--green-light: #3cd4a7;
$color-secondary--green-lighter: #dcfdf3;
$color-secondary--red: #fa3e15;
$color-secondary--red-light: #f99079;
$color-secondary--red-lighter: #ffd7ce;
$color-secondary--purple: #a477f5;

$color-tertiary--grey: #f0f0f0;
$color-tertiary--grey-light: #f8f8f8;
$color-tertiary--black: #0e2532;
$color-tertiary--black-dark: #000;
$color-tertiary--black-alpha-006: rgb(0 0 0 / 6%);
$color-tertiary--black-alpha-03: rgb(0 0 0 / 30%);
$color-tertiary--black-alpha-06: rgb(0 0 0 / 60%);
$color-tertiary--white: #fff;
$color-tertiary--grey-light: #f8f8f8;
$color-tertiary--grey-dark: #ccc;
$color-tertiary--grey-darker: #404040;

// Miles & me primary colors
$color-primary--shell: #dd1d21;
$color-primary--shell-dark: #b0171a;
$color-secondary--shell: #ffc600;
$color-grey--shell: #595959;

// Theme variables
$theme-map-default: (
  --color-primary: $color-primary--blue,
  --color-primary-light: $color-primary--blue-light,
  --color-primary-lighter: $color-primary--blue-lighter,
  --color-primary-dark: $color-primary--blue-dark,
  --color-primary-darker: $color-primary--blue-darker,
  --color-secondary: $color-secondary--green,
  --color-secondary-dark: $color-secondary--green-dark,
  --color-cta: $color-secondary--pink,
  --color-cta-light: $color-secondary--pink-light,
  --color-cta-dark: $color-secondary--pink-darker,
  --nav-link: var(--color-primary),
  --box-shadow--level1: $box-shadow--level1,
  --box-shadow--level2: $box-shadow--level2,
  --box-shadow--level3: $box-shadow--level3
);

$theme-map-shell: (
  --color-primary: $color-primary--shell,
  --color-primary-light: $color-tertiary--grey,
  --color-primary-lighter: $color-tertiary--grey-light,
  --color-primary-dark: $color-primary--shell-dark,
  --color-primary-darker: $color-primary--shell-dark,
  --color-secondary: $color-primary--shell,
  --color-secondary-dark: $color-primary--shell-dark,
  --color-cta: $color-primary--shell,
  --color-cta-dark: $color-primary--shell-dark,
  --color-cta-light: $color-primary--shell,
  --nav-link: $color-tertiary--white,
  --box-shadow--level1: $box-shadow--shell--level1,
  --box-shadow--level2: $box-shadow--shell--level2,
  --box-shadow--level3: $box-shadow--shell--level3
);

// Border
$border--default: 2px solid var(--color-primary-light);
$border--dashed: 2px dashed var(--color-primary-light);
$border--dark: 2px solid var(--color-primary);
$border--lighter: 2px solid var(--color-primary-lighter);

// Fonts
/* stylelint-disable-next-line value-keyword-case */
$font-family--roboto: "Roboto", Helvetica, Arial, sans-serif;
/* stylelint-disable-next-line value-keyword-case */
$font-family--mic32: "Mic 32 New Rounded Web", Helvetica, Arial, sans-serif;
$font-family--base: $font-family--roboto;

$font-size--xsm: 0.5rem; // 8px
$font-size--sm: 0.75rem; // 12px
$font-size--smd: 0.875rem; // 14px
$font-size--md: 1rem; // 16px
$font-size--ml: 1.125rem; // 18px
$font-size--lg: 1.25rem; // 20px
$font-size--xlg: 1.5rem; // 24px
$font-size--xxlg: 2rem; // 32px
$font-size--xxxlg: 2.5rem; // 40px

$font-weight--normal: 400;
$font-weight--bold: 700;

// Gutters
$gutter--xxs: 0.25rem; // 4px
$gutter--xsm: 0.5rem; // 8px
$gutter--xss: 0.8125rem; // 13px
$gutter--sm: 1rem; // 16px
$gutter--md: 1.5rem; // 24px
$gutter--lg: 2rem; // 32px
$gutter--xlg: 3rem; // 48px
$gutter--xxlg: 4.5rem; // 72px

// Heading
$headings: "h1", "h2", "h3", "h4", "h5", "h6";

// Icon sizes
$icon-size--base: 1.5rem; // 24px
$icon-size--sm: 1.125rem; // 18px
$icon-size--md: 2.5rem; // 40px
$icon-size--lg: 3rem; // 48px

// Line heights
$line-height--base: 1; // 16px
$line-height--sm: ($line-height--base * 0.8334);
$line-height--md: ($line-height--base * 1.2);
$line-height--lg: ($line-height--base * 1.8);

// Transitions
$transition-easing--base: cubic-bezier(0.25, 0, 0, 1);

// Gradients
$gradient--primary: linear-gradient(90deg, rgba($color-tertiary--black-dark, 0.4) 0%, rgba($color-tertiary--black-dark, 0) 100%);

// Logo
$logo--url: url("#{$image-path}logo/logo_air-miles.png");
$logo--shell--url: url("#{$image-path}logo/logo_shell.png");
$logo--miles-and-me--url: url("#{$image-path}logo/logo_miles-me.svg");
$logo--stamp--url: url("#{$image-path}logo/logo_air-miles--stamp.png");
$pay-off--url: url("#{$image-path}logo/pay-off_air-miles.svg");

// Other
$mobile-navbar-height: 50px;
$card-image-height: 180px;
$sticker--md: 80px;

$z-index: (
  "toast",
  "modal",
  "header",
  "search",
  "sheet",
  "backdrop",
  "cookie",
  "navigation",
  "language-switcher",
  "main",
  "footer",
  3,
  2,
  1
);
