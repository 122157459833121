.payment-feedback {
  &--failed,
  &--failedPayment,
  &--failedFunds,
  &--failedAccount {
    h3 {
      color: $color-secondary--red;
    }
    .payment__cta i {
      background-color: $color-secondary--red;
    }
  }

  &--success {
    h3 {
      color: $color-secondary--green;
    }
    .payment__cta i {
      background-color: $color-secondary--green;
    }
  }

  &__body {
    margin: $gutter--sm 0;
  }
}
