@use "sass:math";

.table {
  width: 100%;

  thead,
  tr {
    border-bottom: $border--default;
  }

  th,
  td {
    padding: $gutter--xsm * 1.5 0;
    text-align: left;
    vertical-align: middle;
    padding-right: $gutter--xsm;
    line-height: $line-height--md;

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 0;
    }
  }

  th {
    padding-top: 0;
  }

  &__points {
    &-amount {
      font-size: $font-size--lg;
      line-height: $line-height--md;
    }

    &-label {
      font-size: $font-size--sm;
      margin-left: math.div($gutter--xsm, 2);
      line-height: $line-height--md;
    }
  }

  &__heading {
    &--sortable {
      display: flex;

      .icon {
        background-color: $color-tertiary--black;
      }
    }

    &--sorted {
      h5 {
        color: var(--color-primary);
      }

      .icon {
        background-color: var(--color-primary);
      }
    }
  }

  .sticker {
    padding-left: $gutter--sm;
    padding-right: $gutter--sm;
  }

  @include breakpoint($break--sm, max) {
    font-size: $font-size--sm;
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead {
      border-bottom: 0;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td {
      position: relative;
      padding: $gutter--xsm 0;

      &:first-child {
        padding-top: $gutter--sm;
      }

      &:last-child {
        padding-bottom: $gutter--xsm * 1.5;
      }
    }

    th,
    td {
      padding-right: 0;
    }

    tr {
      &:first-child {
        td {
          padding-top: 0;
        }
      }

      &:not(:last-child) {
        td:not(:first-child) {
          padding-top: 0;
        }
      }
    }
  }

  &.loading {
    #{$headings},
    span,
    i {
      @extend .loader--skeleton;
    }
  }
}
