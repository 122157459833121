.cookie-banner {
  width: 100%;
  bottom: 0;
  position: fixed;
  box-shadow: var(--box-shadow--level3);
  background-color: $color-tertiary--white;
  padding: $gutter--lg;
  z-index: z-index("cookie");

  @include breakpoint($break--sm, max) {
    padding: $gutter--sm;
    bottom: $mobile-navbar-height + var(--ion-safe-area-bottom);
  }

  &__container {
    max-width: 660px;
  }

  &__title {
    padding-bottom: $gutter--xsm;
  }

  &__body {
    padding-bottom: $gutter--md;
  }
}
