air-slider {
  width: 100%;
}

.slider {
  display: flex;
  flex-direction: column;

  &__input-thresholds {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $gutter--sm;

    input {
      min-width: 60px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      -moz-appearance: textfield;
    }

    span {
      margin: 0 $gutter--xss;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &__slide {
    display: flex;

    @include breakpoint($break--md, max) {
      flex-direction: column;
    }

    &-thresholds {
      display: flex;
      flex: 100%;
      align-items: center;

      label {
        font-size: $font-size--ml;
        font-weight: $font-weight--bold;
        margin: 0;
        min-width: 60px;
        text-align: center;
      }

      .ngx-slider {
        margin: 6px;

        .ngx-slider-bar-wrapper {
          margin-top: 0;
          padding-top: 0;
          height: 12px;
        }

        .ngx-slider-bar {
          background: $color-primary--blue-light;
          height: 4px;
        }

        .ngx-slider-selection {
          background: $color-primary--blue;
        }

        .ngx-slider-pointer {
          width: 16px;
          height: 16px;
          top: auto; /* to remove the default positioning */
          bottom: -6px;
          background-color: $color-primary--blue;
          outline-color: $color-primary--blue-darker;

          &::after {
            display: none;
          }
        }

        .ngx-slider-bubble {
          display: none;
        }
      }

      &--sticker {
        margin-left: $gutter--xsm;
        align-self: flex-end;

        @include breakpoint($break--md, max) {
          margin-top: $gutter--sm;
        }

        .sticker--sm {
          margin: 0;
        }
      }
    }
  }
}
