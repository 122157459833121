@import "../mixins/background-smile";

.sticker {
  font-family: $font-family--mic32;
  background-color: var(--color-primary);
  border-radius: 50%;
  color: $color-tertiary--white;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: $sticker--md;
  height: $sticker--md;
  border: 2px solid $color-tertiary--white;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &-group {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;

    &--sm {
      flex-direction: row;
      align-items: flex-end;
    }

    &:not([class*="--sm"]):not([class*="--md"]) {
      @include breakpoint($break--sm, max) {
        flex-direction: row;
        align-items: flex-end;
      }
    }
  }

  & + &:not([class*="--md"]) {
    height: 40px;
    padding-left: $gutter--lg !important;
    margin-left: calc(0px - $gutter--sm * 2.5);
    margin-right: 0px;
    z-index: 0;

    &:not([class*="--sm"]) {
      @include breakpoint($break--sm, min) {
        margin-left: calc(0px - $gutter--md / 2);
        padding-left: unset !important;
        height: 55px;
        width: unset;
        aspect-ratio: 1/1;
        z-index: 1;
      }
    }
  }
  & + [class*="--md"] {
    margin-left: calc(0px - $gutter--md / 2);
    padding-left: 0;
    height: 55px;
    width: unset;
    aspect-ratio: 1/1;
    z-index: 1;
  }

  span {
    line-height: $line-height--md;
    @include breakpoint($break--sm, max) {
      white-space: pre;
    }
  }

  &__top-label {
    font-size: $font-size--sm;
  }

  &__label {
    z-index: 1;
    font-size: $font-size--md * 0.75;
    text-align: center;
  }

  &__counter {
    z-index: 1;
    font-size: $font-size--xlg;
    min-width: $gutter--md;
    text-align: center;
  }

  &--miles,
  &--donate {
    @include background-smile(rgba(255, 255, 255, 0.2), "top", 52%, "regular");
  }

  &:not([class*="--sm"]):not([class*="--md"]) {
    @include breakpoint($break--sm, max) {
      display: inline-flex;
      width: auto;
      height: 40px;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      padding: $gutter--xsm * 0.5 $gutter--xsm;
      border-radius: 20px;
      margin-right: $gutter--xsm;

      .sticker {
        & {
          margin-top: $gutter--xsm * 0.5;
          margin-right: $gutter--xsm;
        }

        &__counter {
          margin: 0 0 0 4px;
          font-size: $font-size--ml;
        }

        &__label {
          margin: 0 4px;
          white-space: nowrap;
        }

        span {
          margin-right: $gutter--xsm * 0.5;
        }
      }

      &.sticker--miles {
        .sticker__label {
          width: $gutter--lg;
          white-space: pre-wrap;
          text-align: start;
          margin-left: calc($gutter--xsm / 2);
          word-wrap: normal;
        }
      }

      &.sticker--euro {
        .sticker__counter {
          margin-right: 4px;
        }
      }

      & + .sticker {
        margin-top: $gutter--xsm;

        .sticker__counter {
          font-size: $font-size--md;
        }
      }
    }
  }

  &--red {
    background-color: $color-secondary--red-lighter;
    color: $color-secondary--red;

    &::before {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  &--green {
    background-color: var(--color-secondary);
    color: $color-tertiary--white;
  }

  &--md {
    width: $sticker--md;
    height: $sticker--md;
  }

  &--sm {
    display: inline-flex;
    width: auto;
    height: 40px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    padding: $gutter--xsm * 0.5 $gutter--xsm;
    border-radius: 20px;

    .sticker__counter {
      margin: 0 0 0 4px;
      font-size: $font-size--lg;
    }

    .sticker__label {
      white-space: nowrap;
    }

    & {
      margin-top: $gutter--xsm * 0.5;
      margin-right: $gutter--xsm;
    }

    & + .sticker[class*="--sm"] {
      height: 40px;
    }

    &.sticker--miles {
      .sticker__label {
        width: $gutter--lg;
        white-space: unset;
        text-align: start;
        margin-left: calc($gutter--xsm / 2);
        word-wrap: normal;
      }
    }

    &.sticker--euro {
      .sticker__counter {
        margin-right: 4px;
      }
    }
  }

  &--miles-only {
    background-color: var(--color-primary-darker);
    z-index: 0;

    &--sm {
      @extend .sticker--sm;
      width: auto;
      height: 24px;
      border-radius: 100px;
    }
    span {
      white-space: break-spaces;
    }
  }

  &--not-available {
    background-color: $color-tertiary--black-alpha-06;

    &--sm {
      @extend .sticker--sm;
      width: auto;
      height: 24px;
      border-radius: 100px;
    }
  }

  &--euro {
    background-color: var(--color-primary-light);
    z-index: 0;

    &--sm {
      @extend .sticker--sm;
      width: auto;
      height: 40px;

      .sticker__counter {
        font-size: $font-size--smd;
      }
    }

    span {
      font-size: $font-size--smd;
      color: var(--color-primary);
    }
  }

  &--discount {
    background-color: $color-secondary--purple;
    z-index: 0;

    span {
      font-size: $font-size--smd;
      color: $color-tertiary--white;
    }

    span.sticker__label {
      font-size: $font-size--sm;
      font-weight: $font-weight--normal;
    }
  }

  &--discount-only {
    span.sticker__counter {
      font-size: $font-size--lg;
    }

    background-color: var(--color-secondary);
  }

  &--donate {
    background-color: var(--color-secondary);
    color: $color-tertiary--white;

    .sticker__label {
      font-size: $font-size--md;
    }
  }

  &--reward-activated {
    background-color: var(--color-cta);
  }

  &--blue-light {
    background-color: var(--color-primary-light);
    color: var(--color-primary);
  }
}
