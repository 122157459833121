.inbox-header {
  display: flex;
  justify-content: space-between;

  &__back {
    margin-right: -1.125rem;

    @include breakpoint($break--md, min) {
      display: none !important;
    }
  }
}

.inbox {
  display: flex;
  gap: $gutter--lg;

  &__message {
    transition: opacity 0.25s $transition-easing--base;

    @include breakpoint($break--md, min) {
      margin-top: $gutter--sm;
      margin-bottom: $gutter--sm;
    }

    &--inactive {
      opacity: 0.5;
      pointer-events: none;
    }

    &--unselected {
      @include breakpoint($break--md, max) {
        display: none;
      }
    }
  }

  &__select-message {
    line-height: $gutter--md;
  }

  air-inbox-message-list {
    flex-shrink: 0;
    width: 366px;

    @include breakpoint($break--md, max) {
      width: 100%;
    }
  }
}

.inbox.loading {
  div {
    @extend .loader--skeleton;
    height: 250px;
    border-radius: $gutter--sm;
  }

  div:first-child {
    width: 366px;
    flex-shrink: 0;

    @include breakpoint($break--md, max) {
      display: none;
    }
  }

  div:last-child {
    width: 100%;
  }
}

.inbox-message-list {
  &--selected {
    @include breakpoint($break--md, max) {
      display: none;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: $gutter--xsm $gutter--sm;
    margin-top: $gutter--sm;
  }
}

.inbox-message {
  display: flex;
  gap: $gutter--xsm;
  border-bottom: 2px solid $color-primary--blue-light;
  padding: $gutter--sm 0;
  cursor: pointer;
  transition:
    background-color 0.15s ease-out,
    border-color 0.15s ease-out;

  &:hover {
    .icon-ui-arrow--right--sm {
      transform: translateX(-4px);
    }
  }

  &--inactive {
    pointer-events: none;
  }

  &--active {
    background-color: $color-primary--blue-lighter;
  }

  &__status {
    width: $gutter--md;
    flex-shrink: 0;
  }

  &__content {
    flex-grow: 1;
    min-width: 0; //Needed for ellipsis and flex to work together
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: $gutter--xsm;
  }

  &__title {
    font-weight: $font-weight--bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: $line-height--md;
  }

  &__date {
    flex-shrink: 0;
    font-size: $font-size--sm;
    line-height: $line-height--lg;
  }

  &__summary {
    margin-top: $gutter--xsm;
    font-size: $font-size--sm;
    line-height: $line-height--lg;
  }

  &__arrow {
    transform: translateX(0);
    transition: transform 0.25s $transition-easing--base;
  }

  &__trash {
    align-self: center;
    pointer-events: all;
  }
}

.inbox-message-view {
  &__header {
    display: flex;
    justify-content: space-between;
    gap: $gutter--xsm;
  }

  &__subject {
    color: $color-tertiary--black;
    margin-bottom: $gutter--md;
  }

  &__date {
    flex-shrink: 0;
    font-size: $font-size--sm;
    line-height: $line-height--lg;
  }

  &__image {
    margin-bottom: $gutter--md;
  }

  &__caption {
    font-size: $font-size--smd;
    color: $color-tertiary--grey-dark;
    margin-bottom: $gutter--md;
  }

  &__message {
    line-height: $line-height--lg;
  }

  &__headline {
    font-weight: $font-weight--bold;

    &::after {
      content: "\a\a"; //Add enters that follow the current line-height
      white-space: pre;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: $gutter--md;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    gap: $gutter--sm;
  }
}
