.product-category-list {
  overflow-x: auto;
  display: flex;
  position: relative;
  width: calc(100% + #{$gutter--lg * 2});
  padding: 0 $gutter--lg $gutter--lg $gutter--lg;
  margin-left: (-$gutter--lg);
  margin-bottom: $gutter--sm;

  @include breakpoint($break--sm, max) {
    width: calc(100% + #{$gutter--lg});
    margin-left: (-$gutter--sm);
    margin-bottom: 0;
    padding: 0 $gutter--sm $gutter--sm $gutter--sm;
  }

  &.loading {
    li,
    i,
    span {
      @extend .loader--skeleton;
    }
  }
}
