.__talkjs_launcher {
  z-index: 1 !important;
  bottom: calc($gutter--sm + var(--ion-safe-area-bottom)) !important;
  right: $gutter--md !important;

  @include breakpoint($break--sm, max) {
    right: $gutter--sm !important;
    bottom: calc($mobile-navbar-height + $gutter--sm + var(--ion-safe-area-bottom)) !important;
  }
}

.modal--open,
.toast--open,
.sheet--open,
.mobile-nav--open,
.keyboard--open,
.is-ticket {
  .__talkjs_launcher,
  .__talkjs_popup {
    display: none !important;
  }
}

.__talkjs_popup {
  @include breakpoint($break--sm, max) {
    bottom: calc($mobile-navbar-height + $gutter--sm + var(--ion-safe-area-bottom) + 60px) !important;
  }
  @include breakpoint(420px, max) {
    padding-top: var(--ion-safe-area-top);
    padding-bottom: var(--ion-safe-area-bottom);
    background-color: #fff;
  }
}
