.list {
  &--buttons {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;

    & > * {
      margin-right: $gutter--md;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--products {
  }
}
