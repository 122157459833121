air-search-results-item {
  display: block;
  padding: 0.5rem 0;
  border-bottom: 2px solid $color-primary--blue-light;
}

.results-item {
  display: grid;
  grid-template:
    "title icon" min-content
    "desc desc" min-content / 1fr min-content;
  gap: 0.25rem;
  text-decoration: none;
  color: inherit;
  align-items: center;

  &__icon {
    transform: translate(0, 0);
    transition: transform 0.25s $transition-easing--base;
  }

  &:hover {
    text-decoration: none;
  }

  &:hover &__icon {
    transform: translate(-0.5rem, 0);
  }

  &__parent {
    margin-left: 0.25rem;

    strong {
      margin-left: 0.25rem;
    }
  }

  &__description {
    grid-area: desc;
    font-size: 0.75rem;
  }
}
