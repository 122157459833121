air-title {
  width: 100%;
}

.title {
  margin-bottom: $gutter--md;

  &--has-controls {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: $gutter--xsm;
  }

  &__content {
    flex-grow: 1;

    h1,
    h2,
    h3 {
      margin-bottom: 0;
      &:not(:last-child) {
        margin-bottom: calc($gutter--xsm / 2);
      }
    }
  }
}
