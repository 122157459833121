@use "sass:math";

%inputIconPositioning {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: math.div($gutter--md, 2);
  margin-right: $gutter--sm;
}

label {
  display: block;
  margin-bottom: math.div($gutter--xsm, 2);
  line-height: $line-height--lg;

  @include breakpoint($break--sm, max) {
    font-size: $font-size--md;
  }
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="email"],
input[type="tel"],
input[type="number"],
select,
textarea {
  display: block;
  width: 100%;
  background-color: var(--color-primary-lighter);
  border: 2px solid var(--color-primary-light);
  border-radius: $border-radius--base;
  font-size: $font-size--ml;
  position: relative;
  -webkit-appearance: none;
  transform: translateZ(0); // Older iOS fix
  scroll-margin-bottom: $gutter--sm;

  &:focus,
  &:focus-visible {
    border-color: var(--color-primary);
    outline: none;
  }

  &:disabled {
    border-color: var(--color-primary-light);
    opacity: 0.5;
  }
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="email"],
input[type="tel"],
input[type="number"],
select {
  height: 48px;
  padding: $gutter--xss $gutter--sm;

  & ~ i {
    @extend %inputIconPositioning;
    cursor: pointer;
  }

  &::placeholder {
    line-height: normal;
  }
  &::-moz-placeholder {
    line-height: normal;
  }
  &::-webkit-input-placeholder {
    line-height: normal;
  }
}

textarea {
  height: 160px;
  resize: none;
  line-height: $line-height--lg;
  padding: $gutter--xsm $gutter--sm;
}

// select
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  & + i {
    cursor: pointer;
    pointer-events: none;
  }
}

// radio + checkbox input
input[type="radio"],
input[type="checkbox"] {
  width: 0;
  margin: 0;
  padding: 0;
  display: none;

  & + label {
    padding-left: $gutter--lg;
    padding-bottom: 0;
    margin-bottom: $gutter--sm * 1.3125;
    position: relative;
    width: 100%;
    white-space: break-spaces;
    &:after,
    &:before {
      content: "";
      width: 24px;
      height: 24px;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
    }

    &:before {
      border: 2px solid var(--color-primary-light);
      background-color: var(--color-primary-lighter);
      transform: translate3d(0, -50%, 0);
    }

    &:after {
      background-color: var(--color-primary);
      transform: translate3d(0, -50%, 0);
      background-size: cover;
      opacity: 0;
    }
  }

  &:checked {
    & + label:before {
      border-color: var(--color-primary-darker);
    }

    & + label:after {
      opacity: 1;
    }
  }

  &:disabled + label:after,
  &:disabled + label:before {
    opacity: 0.3;
  }
}

// checkbox input
input[type="checkbox"] {
  & + label {
    padding-left: $gutter--sm * 2.5;

    &:before,
    &:after {
      border-radius: math.div($border-radius--base, 2);
    }
  }

  &:checked {
    & + label:before {
      border-color: var(--color-primary-darker);
      background-color: var(--color-primary);
    }

    & + label:after {
      background-image: url("#{$icon-path}icon-ui-checkmark--sm.svg");
      border: 2px solid var(--color-primary-darker);
    }
  }
}

// radio input
input[type="radio"] {
  & + label {
    &:after,
    &:before {
      border-radius: 100%;
    }
  }

  &:checked + label:after {
    transform: translate3d(0, -50%, 0) scale(0.6);
  }

  &:last-of-type + label {
    margin-bottom: 0;
  }
}
