.content-block--organization-overview {
  .organizations {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-column-gap: $gutter--lg;
    grid-row-gap: $gutter--lg;

    @include breakpoint($break--md, max) {
      grid-template-columns: repeat(4, auto);
    }

    @include breakpoint($break--sm, max) {
      grid-template-columns: repeat(2, auto);
      grid-column-gap: $gutter--sm;
      grid-row-gap: $gutter--sm;
    }

    &__link {
      border: $border--lighter;
      border-radius: $border-radius--base;
      transition: border-color 0.25s $transition-easing--base;
      background-color: $color-tertiary--white;
      position: relative;

      // Square fix
      &::before {
        content: "";
        padding-bottom: 100%;
        display: block;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        padding: $gutter--sm;
        object-fit: contain;
      }

      @media (hover: hover) {
        &:hover {
          border: $border--dark;
        }
      }

      &.active {
        border: $border--dark;
      }
    }
  }
}
