.product-page {
  .grid {
    grid-template-rows: auto 1fr;
  }

  &__carousel {
    margin-bottom: $gutter--lg;
    order: 0;
  }

  &__info {
    order: 1;

    @include breakpoint($break--sm, max) {
      margin-top: $gutter--md;
    }

    &__description {
      @extend %textListStyling;
      margin-bottom: $gutter--lg;
    }
  }

  &__receipt {
    grid-row-end: span 2;
    order: 2;

    &__description {
      margin-top: $gutter--sm;
    }
  }

  &__carousel + &__receipt + &__info {
    order: 3;
  }

  @include breakpoint($break--sm, max) {
    &__carousel {
      order: 0;
    }
    &__receipt {
      order: 1;
    }
    &__info {
      order: 2;
    }
  }
}
