.button {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  font-family: $font-family--mic32;
  border: 0;
  transition:
    box-shadow 0.15s ease-out,
    background-color 0.15s ease-out,
    opacity 0.25s ease-out;
  text-decoration: none !important;
  will-change: width;
  white-space: nowrap;

  @include button--size($gutter--md * 0.5, $gutter--md * 0.75, $font-size--ml, $line-height--base, $gutter--xsm);

  @extend .button--primary;
  cursor: pointer;

  &--add-to-wallet {
    display: block;
    border-radius: $border-radius--base * 2;
    background-color: $color-tertiary--black-dark;
    text-align: center;
    padding: $gutter--sm * 0.625 0;
    margin-bottom: $gutter--xss;
  }

  & > span {
    text-align: center;
    line-height: $line-height--md;
  }

  i:not(:last-child) {
    margin-right: $gutter--xsm;
  }

  &__badge {
    position: absolute;
    width: 16px;
    height: 16px;
    top: -4px;
    right: -4px;
    z-index: 2;
    background-color: var(--color-cta);
    border-radius: 50%;
  }

  // Sizes
  &--full {
    @include button--size($gutter--md * 0.75, $gutter--xlg, $font-size--ml, $line-height--base, $border-radius--base);
    width: 100%;
    justify-content: space-between;

    span {
      flex-grow: 1;
      text-align: center;
    }
  }

  &--wide {
    @include button--size($gutter--md * 0.75, $gutter--lg, $font-size--ml, $line-height--base, $border-radius--base);

    @include breakpoint($break--sm, max) {
      @include button--size($gutter--sm, $gutter--sm, $font-size--base, $line-height--base, $border-radius--base);
    }
  }

  &--md {
    @include button--size($gutter--md * 0.75, $gutter--md * 0.75, $font-size--base * 1.1875, $line-height--base, $border-radius--base);
    height: 48px;
  }

  &--sm {
    @include button--size($gutter--sm, $gutter--sm, $font-size--base, $line-height--base, $border-radius--base);
    height: 40px;
  }

  @include breakpoint($break--sm, max) {
    @include button--size($gutter--sm, $gutter--sm, $font-size--base, $line-height--base, $border-radius--base);
    height: 40px;
  }

  // Colors
  &--primary {
    background-color: var(--color-primary);
    color: $color-tertiary--white;

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-primary-darker);
      }
    }

    &:focus,
    &:active {
      background-color: var(--color-primary-darker);
      box-shadow: inset 0px 0px 0px 2px var(--color-primary-darker);
    }
  }

  &--primary-darker {
    background-color: var(--color-primary-darker);
    color: $color-tertiary--white;

    @media (hover: hover) {
      &:hover {
        background-color: $color-tertiary--white;
        color: $color-primary--blue;
      }
    }

    &:focus,
    &:active {
      background-color: $color-tertiary--white;
      color: var(--color-primary);
      box-shadow: inset 0px 0px 0px 2px var(--color-primary-darker);
    }
  }

  &--secondary {
    background-color: $color-tertiary--white;
    color: var(--color-primary);

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-primary-lighter);
      }
    }
    &:focus,
    &:active {
      background-color: var(--color-primary-lighter);
      box-shadow: inset 0px 0px 0px 2px var(--color-primary-light);
    }

    i.icon {
      color: var(--color-primary);
    }
  }

  &--secondary-darker {
    background-color: var(--color-primary-lighter);
    color: var(--color-primary);

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-primary-light);
      }
    }
    &:focus,
    &:active {
      background-color: var(--color-primary-light);
      box-shadow: inset 0px 0px 0px 2px var(--color-primary-light);
    }

    i.icon {
      color: var(--color-primary);
    }
  }

  &--tertiary {
    background-color: var(--color-primary-lighter);
    color: var(--color-primary);

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-primary-light);
      }
    }
    &:focus,
    &:active {
      background-color: var(--color-primary-light);
      box-shadow: inset 0px 0px 0px 2px var(--color-primary-light);
    }
  }

  &--cta {
    background-color: var(--color-cta);
    color: $color-tertiary--white;

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-cta-dark);
      }
    }

    &:focus,
    &:active {
      color: $color-tertiary--white;
      background-color: var(--color-cta-dark);
      box-shadow: none;
    }

    i:not(.loader--spinner) {
      background-color: $color-tertiary--white;
    }
  }

  &--destructive {
    color: $color-secondary--red;

    .icon {
      background-color: $color-secondary--red;
    }
  }

  &--border {
    background-color: $color-tertiary--white;
    box-shadow: inset 0px 0px 0px 2px var(--color-primary);
    color: var(--color-primary);

    @media (hover: hover) {
      &:hover {
        background-color: $color-tertiary--white;
        box-shadow: inset 0px 0px 0px 2px var(--color-primary-darker);
        color: var(--color-primary-darker);
      }
    }

    &:focus,
    &:active {
      background-color: $color-tertiary--white;
      box-shadow: inset 0px 0px 0px 2px var(--color-primary);
      color: var(--color-primary);
    }

    &--white {
      box-shadow: inset 0px 0px 0px 2px $color-tertiary--white;
      color: $color-tertiary--white;

      @media (hover: hover) {
        &:hover {
          box-shadow: inset 0px 0px 0px 2px $color-tertiary--grey-dark;
          color: $color-tertiary--grey-dark;
        }
      }

      &:focus,
      &:active {
        background-color: transparent;
        box-shadow: inset 0px 0px 0px 2px $color-tertiary--grey-dark;
        color: $color-tertiary--white;
      }
    }

    &--secondary {
      box-shadow: inset 0px 0px 0px 2px var(--color-secondary);
      color: var(--color-secondary);

      @media (hover: hover) {
        &:hover {
          box-shadow: inset 0px 0px 0px 2px var(--color-secondary-dark);
          color: var(--color-secondary-dark);
        }
      }

      &:focus,
      &:active {
        box-shadow: inset 0px 0px 0px 2px var(--color-secondary-dark);
        color: var(--color-secondary);
      }
    }

    &--transparent {
      background-color: transparent;
      box-shadow: inset 0px 0px 0px 2px var(--color-primary);
      color: var(--color-primary);

      @media (hover: hover) {
        &:hover {
          background-color: transparent;
          box-shadow: inset 0px 0px 0px 2px var(--color-primary-darker);
          color: var(--color-primary-darker);
        }
      }

      &:focus,
      &:active {
        background-color: transparent;
        box-shadow: inset 0px 0px 0px 2px var(--color-primary-darker);
        color: var(--color-primary);
      }
    }
  }

  &--transparent {
    background-color: transparent;

    @media (hover: hover) {
      &:hover {
        background-color: transparent;
      }
    }
  }

  &--disabled,
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &--full-width {
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }

  &--category {
    width: 140px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-radius: $border-radius--base;
    border: 2px solid $color-primary--blue-light;
    background-color: $color-primary--blue-lighter;
    color: $color-primary--blue;

    span {
      font-size: $font-size--sm * 1.125;
      color: currentColor;
      margin-top: $gutter--xsm;
    }

    i {
      margin-right: 0;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $color-primary--blue-light;
      }
    }

    &:focus,
    &:active {
      background-color: var(--color-primary-lighter);
    }
  }

  &--feedback {
    width: 48px;
    justify-content: center;
    border: 2px solid var(--color-primary-light);

    @include breakpoint($break--sm, max) {
      width: 40px;
    }
  }

  // Group
  &__group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;

    .button {
      margin: $gutter--xsm $gutter--sm 0 0;
      white-space: nowrap;

      &--full-width {
        margin: $gutter--xsm 0 0 0;
      }

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      @include breakpoint($break--sm, max) {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--right {
      justify-content: flex-end;

      .button {
        margin: 0 0 0 $gutter--sm;
      }
    }

    &--stretch {
      justify-content: space-between;
    }

    &--center {
      justify-content: center;
    }

    &--full-width {
      width: 100%;
    }

    &--modal-form {
      .button {
        margin-top: $gutter--md;
      }
    }

    &--column {
      display: flex;
      flex-direction: column;

      .button {
        @extend .button--full-width;
        margin-bottom: $gutter--sm;

        &:last-child {
          margin-bottom: $gutter--xsm;
        }
      }

      &.button__group--lg {
        .button {
          margin-bottom: $gutter--lg;
          @include breakpoint($break--md, max) {
            margin-bottom: $gutter--sm;
          }
        }
      }
    }
  }
}
