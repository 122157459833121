.product-list {
  grid-row-gap: $gutter--lg;

  @include breakpoint($break--md, max) {
    &__item--large {
      grid-column: span 6 / span 6 !important;
    }
  }

  @include breakpoint($break--sm, max) {
    grid-row-gap: $gutter--sm;

    &__item {
      padding-top: $gutter--sm;

      &--large {
        padding-top: 0;
        padding-bottom: 0;

        &:first-child {
          margin-top: $gutter--sm;
        }
      }
    }
  }
}
