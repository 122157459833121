.toast {
  &-container {
    position: fixed;
    width: 100%;
    height: auto;
    bottom: var(--ion-safe-area-bottom);
    left: 0;
    z-index: z-index("toast");
    pointer-events: none;
  }

  &__title {
    color: var(--color-primary);
  }

  &__item {
    &:last-child {
      margin-bottom: $gutter--xsm;
    }

    pointer-events: all;
    display: flex;
    background-color: var(--color-primary-light);
    border-radius: $border-radius--base;
    padding: $gutter--sm;
    color: var(--color-primary);
    align-items: center;
    justify-content: space-between;
    margin-top: $gutter--xsm;
    flex-wrap: nowrap;

    @include breakpoint($break--sm, max) {
      flex-direction: column;
      align-items: flex-start;
    }

    &--success {
      background-color: $color-secondary--green-light;
      color: $color-tertiary--black;

      .toast__title {
        color: $color-tertiary--black;
      }
    }

    &--error {
      background-color: $color-secondary--red-lighter;
      color: $color-secondary--red;

      .toast__title {
        color: $color-secondary--red;
      }
    }
  }

  &__content {
    flex-basis: auto;
  }

  &__cta {
    flex-basis: auto;
    margin-left: $gutter--sm;
    align-self: flex-end;

    @include breakpoint($break--sm, max) {
      margin-top: $gutter--sm;
      margin-left: 0;
    }

    .button__group {
      justify-content: flex-end;
      flex-wrap: nowrap;

      .button {
        margin: $gutter--xsm * 0.5 0 0 $gutter--xsm;

        &:first-child {
          margin-top: $gutter--xsm * 0.5;
        }
      }
    }
  }
}
