.expandable-list {
  &__item {
    background-color: var(--color-primary-lighter);
    border-radius: $border-radius--base;
    padding: $gutter--xsm $gutter--sm;
    margin-bottom: $gutter--xsm;
    overflow: hidden;

    &__header {
      transition: color 0.25s $transition-easing--base;
      padding: $gutter--xsm 0;
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      h5 {
        flex: 1;
        margin-right: $gutter--md;
      }

      &:hover {
        h5 {
          color: var(--color-primary);
        }
      }
    }

    &__body {
      color: $color-tertiary--black;

      @extend %textListStyling;
      ul,
      ol {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: $gutter--sm;
        counter-reset: revert;
      }
    }
  }
}

.col-12 .expandable-list {
  max-width: 700px;
}

.content--highlighted {
  .expandable-list {
    &__item {
      background-color: $color-tertiary--white;
    }
  }
}
