.app-consent-sheet {
  overflow: auto;
  .full-sheet {
    &__logo {
      .logo--air-miles {
        max-height: 100%;
      }
    }
  }

  ion-item {
    @extend .text--sm;
    --inner-border-width: 0;
    --padding-start: 0;
    margin-bottom: $gutter--xsm;
    line-height: 1.2;
  }
}
