air-language-switcher {
  position: absolute;
  right: $gutter--sm;
  bottom: #{-$gutter--lg};
}

.language-switcher {
  position: relative;
  z-index: z-index("language-switcher");

  select {
    height: $gutter--lg;
    padding: 0 $gutter--xsm 0 calc($gutter--lg + $gutter--xsm);
    font-size: $font-size--md;
    line-height: $line-height--md;
    color: $color-tertiary--black-dark;
    border: none;
    background-color: $color-tertiary--white;
    border-radius: 0 0 $border-radius--base $border-radius--base;
  }

  &__flag {
    position: absolute;
    pointer-events: none;
    height: $gutter--lg;
    top: 0;
    left: $gutter--xsm;
  }
}

//Hide default Google Translate widget
.skiptranslate {
  display: none !important;
}

//Remove styling added by Google Translate widget
body {
  top: 0 !important;
}

//Hide default Google Translate feedback widget
font > font {
  background-color: transparent !important;
  box-shadow: none !important;
  position: initial !important;
}
