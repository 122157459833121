.content-block--terminate-account {
  @extend .content-block--transfer-points;

  .organizations {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-column-gap: $gutter--lg;
    grid-row-gap: $gutter--lg;
    margin-top: $gutter--lg;

    @include breakpoint($break--sm, max) {
      grid-template-columns: repeat(3, auto);
      grid-column-gap: $gutter--sm;
      grid-row-gap: $gutter--sm;
    }

    &__box {
      border: $border--lighter;
      border-radius: $border-radius--base;
      transition: border-color 0.25s $transition-easing--base;
      position: relative;

      // Square fix
      &::before {
        content: "";
        padding-bottom: 100%;
        display: block;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: auto;
        padding: $gutter--sm;
        object-fit: contain;
      }

      .button {
        position: absolute;
        top: -2px;
        right: -2px;
        z-index: 1;
        pointer-events: none;
        opacity: 0;
        width: 40px;
        height: 40px;
        transition: opacity 0.25s $transition-easing--base;

        i {
          margin: auto;
          background-color: $color-tertiary--white;
        }
      }

      @media (hover: hover) {
        &:hover {
          border: $border--dark;
        }
      }

      &.active {
        border: $border--dark;

        .button {
          opacity: 1;
        }
      }
    }
  }
}
