air-search-bar {
  display: contents;
  air-search-bar-input {
    margin: 0 auto 0 $gutter--xxlg;
    @include breakpoint($break--md, max) {
      margin: 0;
    }
  }
}

air-search-bar-input {
  margin: 0;
  position: relative;
  z-index: z-index("header");
  flex-basis: 366px;
  max-width: 366px;
  display: flex;
  border-radius: 24px;
  align-content: center;

  @include breakpoint($break--md, max) {
    margin-left: 0;
    flex: 1;
  }

  @include breakpoint($break--sm, max) {
    flex-basis: 100%;
    order: 10;
    max-width: none;
    border-radius: 0;
    width: 100%;
    position: absolute;
    margin: 0 -0.5rem;
    padding: 0.5rem;
    background-color: $color-tertiary--white;
    transition:
      opacity 0.3s,
      transform 0.3s;
    transform: translate(-100%, 0);
    opacity: 0;
  }

  .search--bar {
    flex: 1;
    position: relative;

    &__icon,
    &__cancel {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      z-index: 1;
      align-items: center;
      justify-content: center;
    }
    &__icon {
      margin-left: $gutter--sm;
      width: $gutter--md;
      height: $gutter--md;
    }
    &__cancel {
      right: 0;
      width: $gutter--sm;
      height: $gutter--sm;
      line-height: $gutter--sm;
      margin-right: $gutter--sm;
      border-radius: 50%;
      background: $color-primary--blue;
      padding: 0;

      .icon {
        background-color: $color-tertiary--white;
        width: $gutter--sm;
        height: $gutter--sm;
      }
    }

    &__close {
      @extend .button--transparent;
    }

    &__input {
      color: $color-tertiary--black;
      border-radius: 24px;
      height: 48px;
      padding: $gutter--sm $gutter--md * 2;

      &.ng-dirty.ng-invalid {
        outline-color: $color-secondary--red;
      }
    }
  }
}

.activeSearch air-search-bar-input {
  @include breakpoint($break--sm, max) {
    transform: translate(0, 0);
    z-index: 10;
    opacity: 1;
  }
}

.button--search {
  color: $color-tertiary--white;
  font-size: 0;
  margin-left: 1rem;
  padding: 0;
}
