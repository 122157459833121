.purchased-vouchers {
  .table {
    &__row {
      &--expired {
        td:not(:last-child) {
          opacity: 0.5;
        }
      }
    }

    @include breakpoint($break--sm, max) {
      tr {
        td {
          .d--hidden {
            display: none;
          }

          &:not(:first-child) {
            padding-top: 0;
          }
        }
      }

      thead tr {
        position: static;
        top: auto;
        left: auto;

        th:not(:first-child) {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }
      }

      &__heading {
        padding-top: $gutter--xsm * 1.5;
      }

      &__row--supplier {
        display: flex;
        justify-content: space-between;
        align-content: center;
        border-bottom: $border--default;
        flex-wrap: wrap;

        td {
          padding-top: $gutter--xsm !important;
          padding-bottom: $gutter--xsm !important;
        }

        h5 {
          line-height: $line-height--lg;
        }
      }
    }

    @include breakpoint($break--md, min) {
      &__row {
        &--supplier {
          h5 {
            line-height: $line-height--lg;
          }
        }
      }
    }
  }

  &__load-more {
    margin-top: $gutter--lg;
    display: flex;
    justify-content: center;

    @include breakpoint($break--sm, max) {
      margin-top: $gutter--sm;
    }
  }

  &.loading {
    button {
      @extend .loader--skeleton;
    }

    .table td:first-child {
      padding-top: $gutter--md;
    }
  }
}
