.payment-options {
  &__header {
    color: var(--color-primary);
    margin-bottom: $gutter--sm * 0.75 !important;
  }

  .payment-methods {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    #{ headings } {
      margin-bottom: $gutter--xsm;
    }

    &-block {
      flex: 1 0 100%;
      margin-right: $gutter--sm;
    }

    &-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: stretch;
      align-items: stretch;
      margin-left: 0;
      padding-left: 0;
      padding-bottom: $gutter--xsm;

      &__item {
        @extend .button;
        margin-left: 0;
        width: 100%;

        img {
          @extend .icon--md;
          margin-right: $gutter--xsm;
        }

        @media (hover: hover) {
          &:hover {
            background-color: $color-tertiary--white;
            box-shadow: inset 0px 0px 0px 1px var(--color-primary-light);
          }
        }

        h5 {
          margin-top: 3px; // offset for font centering, intentionally specific instead of gutter
        }

        @extend .button--secondary;

        &.selected {
          @extend .button--border;
        }
      }
    }
  }

  .payment__cta {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
  }
}
