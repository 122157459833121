.campaign-page {
  &__carousel {
    margin-bottom: $gutter--lg;
  }

  &__validity {
    font-weight: $font-weight--bold;
    margin-bottom: $gutter--lg;
  }

  &__activation {
    @include breakpoint($break--sm, max) {
      margin-bottom: $gutter--lg;
    }
  }
}
