.share-referral-link {
  .referral__link {
    display: flex;
    margin-top: $gutter--md;
    flex-direction: column;

    &--value {
      text-overflow: ellipsis;
      cursor: pointer;
    }

    &.loading {
      gap: $gutter--xsm;

      p {
        padding: 0;
      }
    }
  }

  .button__group {
    margin-top: $gutter--sm;
    gap: $gutter--xsm;

    @include breakpoint($break--xsm, min) {
      flex-wrap: unset;
    }

    .button {
      margin: 0;
    }
  }
}

.referral-rewards {
  h1,
  h3 {
    margin-bottom: 0;
  }

  &__description {
    margin-top: $gutter--xsm;
  }

  &::before {
    content: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: $gutter--sm;
  }

  .card-list--rewards-secondary {
    margin-top: $gutter--md;
    padding: 0;

    @include breakpoint($break--md, min) {
      .card-list--items {
        grid-column-gap: 0;
      }
    }
  }

  .card-list--rewards-highlighted {
    margin-top: $gutter--md;
    padding: 0;
  }

  &::before {
    content: none;
  }
}
