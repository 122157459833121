@use "sass:math";

air-personal-navigation {
  width: 100%;
  margin-bottom: calc($gutter--xlg + $gutter--xsm);

  @include breakpoint($break--sm, max) {
    margin-bottom: $gutter--sm;
  }
}

.nav--personal-page {
  air-link-list {
    display: flex;
    flex-wrap: wrap;
  }

  .link-list__group {
    background: $color-primary--blue-darker;
    border-radius: $border-radius--smd;
    padding: $gutter--xsm $gutter--xsm 0 $gutter--xsm;
  }

  .link-list__links {
    display: flex;
    flex-wrap: wrap;

    &__item {
      @extend .button;
      @extend .button--primary-darker;
      padding: 0;
      margin: 0 $gutter--xsm $gutter--xsm 0;

      span {
        margin: 0;
        padding: 0;

        & + i {
          display: none;
        }
      }

      a {
        display: flex;
        align-items: center;
        padding: $gutter--sm * 0.75;
        height: 100%;
        text-decoration: none;
        color: inherit;
      }

      &.active,
      &:active &:focus {
        background-color: $color-primary--blue;
        color: $color-tertiary--white;
      }
    }

    & .link-list__section {
      @include breakpoint($break--sm, max) {
        display: none;
      }
    }
  }

  &--mobile {
    margin-top: $gutter--md;
    margin-bottom: $gutter--xsm;
  }

  &--mobile,
  &--mobile-menu {
    @extend .nav--personal;
    z-index: z-index("navigation");

    .button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: var(--color-primary-darker);

      > span {
        color: $color-tertiary--white;
      }

      > .icon {
        background-color: $color-tertiary--white;
      }
    }

    .nav-container {
      right: unset;
      left: 0;
      width: 100%;
    }

    air-link-list {
      opacity: 0;
      visibility: hidden;
      transition: $transition--disappear;
      padding-top: $gutter--xsm;

      .link-list__links__item {
        width: 100%;
      }
    }

    @media (hover: hover) {
      @at-root .nav--open air-link-list {
        opacity: 1;
        visibility: visible;
        transition: $transition--appear;
      }
    }

    .nav--open air-link-list {
      opacity: 1;
      visibility: visible;
      transition: $transition--appear;
    }
  }
}

.loading {
  .nav--personal {
    visibility: hidden;
  }
}
