modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: z-index("modal");
}

.modal {
  background-color: $color-tertiary--white;
  width: calc(100% - #{$gutter--xlg * 2});
  max-width: 600px;
  text-align: left;
  position: fixed;
  overflow: hidden;
  box-shadow: var(--box-shadow--level3);
  border-radius: $border-radius--base;
  padding: $gutter--lg 0;
  z-index: z-index("modal");
  will-change: opacity, transform, height;

  & > * {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint($break--sm, max) {
    top: auto;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    max-width: 100%;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: $gutter--sm 0 calc(#{$gutter--sm} + var(--ion-safe-area-bottom)) 0;
  }

  &--loading {
    #{$headings},
    span,
    p,
    input,
    label {
      @extend .loader--skeleton;
    }
  }

  &__header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $gutter--sm;
    padding: 0 $gutter--lg;

    @include breakpoint($break--sm, max) {
      padding: 0 $gutter--sm;
    }

    h2,
    h3 {
      margin-bottom: 0;
    }
  }

  &__content {
    max-height: calc(var(--app-height) - 80px - 6rem); // -80px for modal-header and padding, 6 rem for header and margin
    overflow-y: auto;
    padding: $gutter--xsm $gutter--lg;

    @include breakpoint($break--sm, max) {
      padding: $gutter--xsm $gutter--sm;
    }

    .form {
      margin-top: (-$gutter--xsm);
    }
  }

  &__text {
    margin-bottom: $gutter--lg;

    @include breakpoint($break--sm, max) {
      margin-bottom: $gutter--sm;
      p {
        font-size: $font-size--md;
      }
    }
  }

  &__footer {
    justify-content: flex-end;
    width: 100%;
    margin-top: $gutter--lg;
    padding: 0 $gutter--lg;

    &--full-width-ends {
      @extend .modal__footer;
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 100%;
    }

    @include breakpoint($break--sm, max) {
      margin-top: 0;
      padding: $gutter--sm $gutter--sm 0 $gutter--sm;
    }

    @include breakpoint($break--md, max) {
      width: 100%;
    }
  }

  &__button {
    &--close {
      padding: 0 !important;
      height: auto !important;
      min-height: 32px !important;
      align-self: flex-start !important;

      &:active,
      &:focus {
        box-shadow: none !important;
      }
    }
  }

  &-backdrop {
    background-color: rgba($color-tertiary--black, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: z-index("backdrop");
    will-change: opacity;

    &--darker {
      background-color: rgba($color-tertiary--black, 0.9);
    }
  }
}
