.donation-counter {
  display: flex;

  @include breakpoint($break--md, max) {
    flex-direction: column;
  }

  &__panel {
    &-content {
      flex-grow: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: calc($gutter--lg * 2);
      justify-content: flex-end;
      height: 404px;

      @include breakpoint($break--md, max) {
        padding: 10rem $gutter--lg $gutter--xlg $gutter--lg;
      }

      @include breakpoint($break--sm, max) {
        padding-top: calc($gutter--xxlg * 2);
        padding-bottom: $gutter--xlg;
      }

      @include breakpoint($break--md, min) {
        margin-right: -$gutter--xlg;
      }

      h2,
      .h3,
      span,
      p,
      li {
        color: $color-tertiary--white;
        margin: 0;
      }

      h2 {
        margin-top: $gutter--xsm;

        @include breakpoint($break--sm, max) {
          margin-top: calc($gutter--xsm * 0.5);
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
    }

    &-image {
      air-focal-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:before {
        content: "";
        position: absolute;
        background-color: $color-primary--blue;
        left: 0;
        top: $gutter--md;
        width: 100%;
        height: calc(100% - (#{$gutter--md} * 2));

        @include breakpoint($break--sm, max) {
          height: 100%;
          top: 0;
        }
      }

      img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: auto;
        max-height: 100%;
        object-fit: contain;
      }
    }

    &-counter {
      @include breakpoint($break--md, max) {
        margin-top: -2rem;
      }

      @include breakpoint($break--md, max) {
        width: auto;
        margin-left: auto;
        margin-right: 32px;
      }

      @include breakpoint($break--sm, max) {
        margin-left: 16px;
        margin-right: 16px;
      }

      air-counter {
        width: 100%;
      }
    }
  }

  &__text {
    h1 {
      margin-bottom: 0;
    }
    @extend %textListStyling;
  }

  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    border-radius: $gutter--md;
    overflow-y: hidden;
    &::before {
      content: "";
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0) 100%);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    figure {
      height: 100%;
      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }

  &__unload {
    margin-top: (-$gutter--xxlg);

    @include breakpoint($break--md, max) {
      margin-top: (-$gutter--lg);
    }

    @include breakpoint($break--sm, max) {
      margin-top: (-$gutter--md);
    }
  }
}
