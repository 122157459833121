.partner {
  &__info {
    @extend .formatted-text--aligned-image;
  }

  &__logo {
    flex: 0 0 100px;
    margin-right: $gutter--lg;

    @include breakpoint($break--sm, max) {
      flex-basis: 50px;
    }
  }

  &__description {
    @extend %textListStyling;
  }
}
