air-my-card {
  display: flex;
  justify-content: center;
  width: 100%;
}

.my-card {
  background: $color-tertiary--white;
  box-shadow: var(--box-shadow--level3);
  border: 1px solid var(--color-primary-light);
  border-radius: $border-radius--base * 2;
  overflow: hidden;
  max-width: 465px;
  height: 268px;

  @include breakpoint($break--md, max) {
    max-width: 338px;
    height: 209px;
  }

  @include breakpoint($break--sm, max) {
    max-width: 343px;
    height: 211px;
  }

  &__barcode {
    padding: $gutter--sm 40px $gutter--sm 0;

    @include breakpoint($break--md, max) {
      padding: 12px 36px $gutter--sm 0;
    }

    @include breakpoint($break--sm, max) {
      padding: 12px 34px $gutter--sm 0;
    }

    display: flex;
    justify-content: center;

    & > div {
      max-width: 100%;
      display: flex;
      align-content: center;
      justify-content: center;
    }

    svg {
      g:first-of-type {
        transform: translate(30px, 10px);
      }
    }
  }

  &__number {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: flex-end;
    padding: $gutter--sm $gutter--md;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      background-image: $logo--url;
      background-repeat: no-repeat;
      background-size: $gutter--lg auto;
      background-position: center 40%;
    }

    &--label {
      color: $color-primary--blue;
      font-size: $font-size--ml;
      font-family: $font-family--mic32;
    }

    &--number {
      position: relative;
      z-index: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      color: $color-primary--blue-darker;
      font-size: $font-size--lg;
      font-family: $font-family--mic32;

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 105px;
        bottom: -38px;
        right: calc(#{$gutter--md + $gutter--sm} * -1);
        margin-top: -100px;
        padding-top: 100px;
        background-image: url("#{$image-path}divider-curve.svg");

        @extend .bg--100;

        @include breakpoint($break--md, max) {
          height: 90px;
          width: 115%;
        }

        @include breakpoint($break--sm, max) {
          height: 45px;
          width: 116%;
        }
      }
    }
  }
}
