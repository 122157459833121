.form {
  &-section {
    &--has-card {
      margin-bottom: $gutter--lg * 1.25;
    }
  }

  &-group {
    &--card-number {
      margin-top: $gutter--lg;
    }
  }

  &--register {
    &__newsletter {
      .toast {
        &__cta {
          text-align: right;
        }
      }

      &-checkbox {
        .form__description {
          margin-top: 0;
        }

        hr {
          margin-bottom: $gutter--lg;
        }
      }
    }
  }
}

air-registration-form {
  .referral-toast {
    margin-bottom: $gutter--md;
  }

  .step-header {
    margin-bottom: $gutter--lg * 2;

    h1 {
      margin-bottom: $gutter--sm;
    }
  }
}
