.d {
  &--block {
    &--xsm {
      @include breakpoint($break--xsm, max) {
        display: block !important;
      }
    }
  }

  &--none {
    &--md {
      @include breakpoint($break--md, max) {
        display: none !important;
      }
    }
    &--sm {
      @include breakpoint($break--sm, max) {
        display: none !important;
      }
    }
    &--xsm {
      @include breakpoint($break--xsm, max) {
        display: none !important;
      }
    }
  }

  &--show {
    &--sm {
      display: none !important;
      @include breakpoint($break--sm, max) {
        display: inherit !important;
      }
    }
  }

  &--hidden {
    visibility: hidden;
  }
}
