.card {
  width: 100%;
  height: 100%;
  margin-right: $gutter--lg;
  transition: box-shadow 0.25s $transition-easing--base;
  will-change: box-shadow;
  position: relative;
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-auto-columns: 100%;
  cursor: pointer;
  background: var(--color-primary-lighter);
  border-radius: $border-radius--md;

  & air-card-list-item > a,
  & > a {
    color: inherit;
    text-decoration: none;
    display: inherit;
    width: 100%;
    height: 100%;
  }

  & air-card-list-item {
    width: 100%;
    display: grid;
    @include breakpoint($break--md, max) {
      display: block;
    }
  }

  figure {
    position: relative;
    padding-bottom: 60%;
    height: 0;
  }

  img {
    position: absolute;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  h4 {
    margin-bottom: 4px;
  }

  &--product--large,
  &--custom--large {
    h4 {
      color: inherit;
    }
  }

  &__image {
    grid-column: 1/1;
    grid-row: 1/1;

    img {
      border-radius: $border-radius--md $border-radius--md 0 0;

      @include breakpoint($break--sm, max) {
        border-radius: $border-radius--base;
      }
    }
  }

  &__stickers {
    grid-column: 1/1;
    grid-row: 1/1;
    z-index: 2;
    align-self: flex-end;
    padding: $gutter--xsm;
  }

  &__text {
    position: relative;
    grid-column: 1/1;
    grid-row: 2/2;
    padding: $gutter--sm;

    &:last-child {
      padding-bottom: 0;
    }

    @include breakpoint($break--sm, max) {
      @at-root .content--highlighted & {
        padding-top: 0;
      }
    }
  }

  &__title {
    padding-right: $gutter--lg;
  }

  &__icon {
    position: absolute;
    display: flex;
    top: $gutter--sm * 1.125;
    right: $gutter--sm * 1.125;
    padding: $gutter--xsm;
    border-radius: $border-radius--base;
    background-color: $color-tertiary--black-alpha-06;

    i {
      background-color: $color-tertiary--white;
    }

    @include breakpoint($break--sm, max) {
      top: 0;
      right: 0;
      background-color: unset;

      i {
        background-color: var(--color-primary-light);
      }
    }
  }

  &__link {
    margin-top: $gutter--sm;
  }

  &__cta {
    margin-top: $gutter--sm;
  }

  &--detail {
    .card {
      &__cta {
        @extend .p;
        margin-top: $gutter--lg;
      }
    }
  }

  @include breakpoint($break--sm, min) {
    @at-root .content--primary & {
      transition: 0.3s ease-in-out;
      @media (hover: hover) {
        &:hover {
          box-shadow: var(--box-shadow--level3);
          background: var(--color-primary-light);
        }
      }

      &--no-hover {
        @media (hover: hover) {
          &:hover {
            box-shadow: none;
            cursor: default;
          }
        }
      }
    }
  }

  @include breakpoint($break--md, max) {
    &__cta {
      margin-bottom: $gutter--sm;
    }
  }

  @include breakpoint($break--sm, max) {
    display: grid;

    figure {
      width: 100px;
      height: 100px;
      flex: 0 0 100px;
      padding-bottom: 0;
    }

    &:not(.card--product) {
      h4 {
        margin-top: $gutter--sm;
      }
    }

    &:not(.card--product--large):not(.card--custom--large):not(.card--reward) {
      grid-template-columns: repeat(2, minmax(0px, max-content));
      background-color: unset;
      border-radius: 0;

      .card__image {
        grid-column: 1/1;
        grid-row: span 2;
        align-self: flex-end;
      }

      .card__stickers {
        grid-column: 2/2;
        grid-row: 2/2;
        padding-bottom: 0;
      }

      .card__text {
        padding-top: 0;
        grid-column: 2/2;
        grid-row: 1/1;
      }
    }
  }

  &.loading {
    figure,
    #{$headings},
    p,
    .button,
    .card__title,
    .card__description {
      @extend .loader--skeleton;
    }

    .card__image {
      &:before,
      &:after {
        content: none;
      }
    }

    @media (hover: hover) {
      &:hover {
        box-shadow: none;
      }
    }
  }

  &:is(.card--reward) {
    background: var(--color-primary-lighter);
    margin: 0;
    pointer-events: none;

    &.activated {
      background-color: $color-tertiary--grey-light;

      .card__image,
      .card__text {
        filter: grayscale(100%);
      }
    }

    .card__image {
      border-radius: $border-radius--md $border-radius--md 0 0;
    }

    .card__text {
      padding: 0 $gutter--sm $gutter--sm $gutter--sm;

      h4 {
        margin-top: $gutter--sm;
        margin-bottom: 0;
      }
    }

    .sticker {
      position: absolute;
      top: $gutter--sm;
      left: $gutter--sm;
    }

    @include breakpoint($break--md, max) {
      background-color: var(--color-primary-lighter);
    }
  }
}
