@use "sass:math";

.content-block {
  & + &,
  air-campaign-registration {
    .form {
      margin-top: $gutter--xlg;
    }
  }
}

div.form__description {
  @extend %textListStyling;

  ul,
  ol {
    margin-top: 0;
  }
  margin-top: $gutter--sm;

  @include breakpoint($break--sm, max) {
    p {
      margin-bottom: $gutter--xsm;
    }
  }
}

small.form__description {
  margin-top: $gutter--xsm;
  display: block;
}

.form {
  &__mandatory-label {
    margin-top: $gutter--sm;
    font-size: $font-size--sm;
  }

  .grid {
    grid-column-gap: $gutter--sm;
  }

  &-section {
    margin-bottom: $gutter--lg * 2;

    &--no-padding {
      margin-top: (-$gutter--xlg);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--spaced {
      margin-top: $gutter--sm;
    }

    &--offset {
      margin-top: (-$gutter--sm);
    }

    &--hidden {
      display: none;
    }

    h2 {
      margin-bottom: $gutter--sm;
    }

    &__body {
      margin-bottom: $gutter--lg;
    }
  }

  &-message {
    margin-top: $gutter--xsm;

    &--user-exists,
    &--card-status {
      @extend .form-message;
    }
  }

  &-group {
    display: block;
    position: relative;
    margin-top: $gutter--xss;

    &--icon {
      input[type="text"],
      input[type="date"],
      input[type="password"],
      input[type="email"],
      input[type="tel"] input[type="number"] {
        padding-right: $icon-size--base + $gutter--sm;
      }
    }

    &--tabs {
      display: inline-flex;
      overflow-x: auto;
      width: 100%;
      padding-bottom: $gutter--sm;

      @include breakpoint($break--sm, max) {
        padding-right: $gutter--lg;
        padding-bottom: $gutter--sm;
      }

      input[type="radio"] {
        & + label {
          &:before,
          &:after {
            display: none;
          }

          @extend .button;
          @extend .button--border;
          justify-content: center;
          margin-right: $gutter--sm;
          margin-bottom: 0;
          padding: $gutter--xss $gutter--xsm * 1.5;
          font-size: $font-size--ml;

          @include breakpoint($break--sm, max) {
            @include button--size($gutter--sm, $gutter--sm, $font-size--md, $line-height--base, 8px);
            height: 40px;
            margin-right: math.div($gutter--xsm, 2);
            font-size: $font-size--ml;
          }
        }

        &:last-child {
          & + label {
            margin-right: 0;
          }
        }

        &:checked {
          & + label {
            &:before,
            &:after {
              display: none;
            }

            background-color: var(--color-primary);
            color: $color-tertiary--white;
          }
        }

        & + label {
          white-space: nowrap;
        }
      }
    }

    &--radio {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      margin: $gutter--lg 0 0 0;

      label {
        margin-right: $gutter--lg;
        &:before {
          background-color: $color-tertiary--white;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      input[type="radio"] {
        margin-right: $gutter--xsm;

        & + label {
          margin-bottom: 0;
          width: auto;
        }
      }
    }

    &--list {
      display: flex;
      flex-direction: column;

      .option {
        padding: $gutter--sm;
        border: 2px solid $color-primary--blue-light;
        margin-bottom: $gutter--xsm;

        &.selected {
          background-color: $color-primary--blue-lighter;

          label {
            font-weight: $font-weight--bold;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        input[type="radio"] {
          & + label {
            padding-left: $gutter--md + $gutter--sm;
          }
        }
      }
    }

    &--grecaptcha {
      @extend .form-group;
      margin-top: $gutter--lg !important;

      @include breakpoint($break--sm, max) {
        margin-top: $gutter--md !important;
      }
    }

    &.loading {
      #{$headings},
      span,
      i {
        @extend .loader--skeleton;
      }
    }
  }

  &__checklist {
    line-height: $line-height--lg;
    & > div {
      display: flex;
      align-items: center;

      i {
        margin-right: $gutter--xsm;

        &.icon-ui-checkmark--sm {
          background-color: $color-secondary--green;
        }
      }

      @include breakpoint($break--sm, max) {
        font-size: $font-size--sm;
        margin-bottom: $gutter--xsm;

        i {
          margin-right: 0;
        }
      }
    }
    &--error {
      i {
        background-color: $color-secondary--red;
      }
      span {
        color: $color-secondary--red;
      }
    }

    &--valid {
      span {
        color: $color-tertiary--black-alpha-06;
      }
    }
  }

  &__button {
    margin-top: $gutter--lg;

    &--gutter-xxlg {
      margin-top: $gutter--xxlg;
    }

    @include breakpoint($break--sm, max) {
      margin-top: $gutter--sm;
    }

    &-group {
      margin-top: $gutter--lg * 2;

      @include breakpoint($break--sm, max) {
        margin-top: $gutter--lg;
      }
    }
  }

  &-control {
    &__badge {
      display: flex;
      justify-content: flex-end;
      margin-top: $gutter--xsm;

      .badge {
        z-index: 0;
        user-select: none;
      }

      &--verified {
        margin-top: -10px;
        margin-right: $gutter--sm;
      }
    }
  }

  input.form-control--verified {
    border-color: $color-secondary--green;
  }

  input.form-control--unverified {
    border-color: $color-secondary--orange;
  }

  // Validation styling
  .form-error,
  .form-label--error {
    color: $color-secondary--red;
  }

  input[type="text"],
  input[type="date"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  select,
  textarea {
    &.ng-dirty.ng-invalid {
      border-color: $color-secondary--red;
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    &.ng-dirty.ng-invalid {
      & + label {
        &:before {
          border: 2px solid $color-secondary--red;
        }
      }
    }
  }

  &.form--show-valid {
    // show valid fields

    .form-group--checkmark {
      @extend .form-group--icon;

      position: relative;

      &:after {
        content: "";
        display: block;
        @extend %inputIconPositioning;
        width: $icon-size--base;
        height: $icon-size--base;

        @supports (mask: url("#{$icon-path}icon-ui-checkmark--sm.svg")) {
          mask-image: url("#{$icon-path}icon-ui-checkmark--sm.svg");
          background-color: $color-secondary--green;
        }
      }
    }

    input[type="text"],
    input[type="date"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    select,
    textarea {
      &.ng-dirty.ng-valid {
        border-color: $color-secondary--green !important;
      }
    }
  }

  &.form--submitted {
    input[type="text"],
    input[type="date"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    select,
    textarea {
      &.ng-invalid {
        border-color: $color-secondary--red;
      }
    }

    input[type="radio"],
    input[type="checkbox"] {
      &.ng-invalid {
        & + label {
          &:before {
            border: 2px solid $color-secondary--red;
          }
        }
      }
    }

    &.form--show-valid {
      input[type="text"],
      input[type="date"],
      input[type="password"],
      input[type="email"],
      input[type="tel"],
      input[type="number"],
      select,
      textarea {
        &.ng-valid {
          border-color: $color-secondary--green !important;
        }
      }
    }
  }

  &-label {
    display: block;
    margin-bottom: math.div($gutter--xsm, 2);
    line-height: $line-height--base * 2.4;
  }

  &-error {
    margin-top: math.div($gutter--xsm, 2);
    font-size: $font-size--sm;
    line-height: $line-height--lg;
  }

  @at-root .loading & {
    input,
    select,
    textarea,
    label,
    #{$headings} {
      &,
      &:before,
      &:after {
        @extend .loader--skeleton;
      }
    }
  }
}

.modal__footer {
  .form__button {
    margin-top: 0;
  }
}
