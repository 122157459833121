@use "sass:math";

.file {
  &__drag-and-drop {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $gutter--xsm;
    background-color: $color-primary--blue-lighter;
    border: $border--dashed;
    border-radius: $border-radius--base;
    margin-top: $gutter--xsm;
    padding: math.div($gutter--md, 2);
    cursor: pointer;

    &.drag-active {
      background-color: transparent;
      border: $border--default;

      button {
        @extend .button--disabled;
      }
    }

    p {
      pointer-events: none;
    }

    button > .icon {
      background-color: $color-tertiary--white;
    }
  }

  &__list {
    margin-bottom: $gutter--sm;

    &--label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > div {
      display: flex;
      justify-content: space-between;
      padding-bottom: $gutter--xsm;

      > div {
        display: flex;
        align-items: center;
        gap: $gutter--xsm;
        max-width: calc(100% - $gutter--xlg);

        @include breakpoint($break--sm, max) {
          max-width: calc(100% - $gutter--lg);
        }
      }

      > .button--transparent {
        padding-right: 0;

        > .icon {
          margin-right: 0;
        }
      }
    }

    &--progress {
      position: absolute;
      margin-top: (-$gutter--xss);
      margin-right: $gutter--lg;
      margin-left: $gutter--lg;
      width: calc(100% - ($gutter--lg * 2));
    }
  }
}
