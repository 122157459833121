@use "sass:math";

.progress {
  height: math.div($gutter--xsm, 2);
  border-radius: 2px;

  &::-webkit-progress-bar {
    background-color: $color-primary--blue-light;
    border-radius: 2px;
  }

  &::-webkit-progress-value {
    background-color: $color-primary--blue;
    border-radius: 2px;
  }

  &::-moz-progress-bar {
    background-color: $color-primary--blue-light;
    border-radius: 2px;
  }
}
