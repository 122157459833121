.button {
  &--special {
    width: 48px;
    height: 48px;
    background: $color-secondary--pink;

    .icon {
      margin: 0 auto;
      background-color: $color-tertiary--white;

      &.animated {
        animation: wobble 1.5s ease-in-out 1s 3 both;
      }
    }

    &:active,
    &:focus {
      background: $color-secondary--pink;
      box-shadow: none;
    }
    &:hover {
      background: $color-secondary--pink-light;
    }
    @include breakpoint($break--sm, max) {
      &:hover {
        background: $color-secondary--pink;
      }
    }
  }
}

@keyframes wobble {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateX(-5px) rotate(6deg);
  }
  30% {
    transform: translateX(1.5px) rotate(-6deg);
  }
  45% {
    transform: translateX(-1.5px) rotate(3.6deg);
  }
  60% {
    transform: translateX(0.7px) rotate(-2.4deg);
  }
  75% {
    transform: translateX(-0.5px) rotate(1.2deg);
  }
}
