@mixin background-smile($color, $position, $position-value, $curvature, $index: 0) {
  &::before {
    content: "";
    position: absolute;
    z-index: $index;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: $color;

    @if ($position == "top") {
      bottom: $position-value;
    } @else if($position == "bottom") {
      top: $position-value;
    }

    @if ($curvature == "regular") {
      width: 340%;
      left: -142%;
    } @else if($curvature == "gentle") {
      width: 2200%;
      left: -1100%;
    } @else if ($curvature == "steep") {
      width: 130%;
      left: -63%;
      @include breakpoint($break--sm, max) {
        width: 350%;
        left: -165%;
      }
    }
  }
}
