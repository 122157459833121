.embedded-page__content {
  --ion-background-color: #{$color-tertiary--white};
  .content {
    &-panel {
      box-shadow: none;
      background-color: unset;
    }
    &-section:first-child {
      margin-top: 0;
    }
  }
  .hero.loading {
    background: $color-tertiary--white;
  }
}
