.select-count {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  & > * {
    margin-right: $gutter--xsm;

    &:last-child {
      margin-right: 0;
    }
  }

  &__button {
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius--base;

    &--decrement {
      @extend .select-count__button;
      @extend .button--border;
    }

    &--increment {
      @extend .select-count__button;
      @extend .button--primary;

      i {
        background-color: $color-tertiary--white;
      }
    }

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  input[type="number"] {
    width: 64px;
    text-align: center;
    background-color: $color-tertiary--white;
    border: 2px solid var(--color-primary-light);
    border-radius: $border-radius--base;
    box-shadow: none;
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
