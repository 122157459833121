.content-block--transfer-points {
  display: flex;
  flex-direction: column;

  .interactive-block {
    margin: $gutter--sm 0;
  }

  h3 {
    margin: $gutter--lg 0;
  }

  .button__group {
    .button,
    .sticker {
      margin: $gutter--xsm $gutter--xsm 0 0;
    }
  }

  .sticker {
    cursor: pointer;

    &--inactive {
      background-color: var(--color-primary-light);
      color: var(--color-primary);
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  .form-block {
    margin: $gutter--md 0 $gutter--sm;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
  }

  input {
    &.input-error {
      box-sizing: border-box;
      border: 2px solid $color-secondary--red;
    }
  }
}
