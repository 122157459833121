.content-banner {
  background-color: var(--color-primary);
  // cspell:disable-next-line
  background-image: url("data:image/svg+xml,%3Csvg width='714' height='350' viewBox='0 0 714 350' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='357' cy='175.87' r='349' transform='rotate(-90 357 175.87)' stroke='url(%23paint0_linear_28721_2592)' stroke-width='16'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_28721_2592' x1='357' y1='-181.13' x2='357' y2='532.87' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0.16'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  border-radius: $border-radius--lg;
  overflow: hidden;
  @include breakpoint($break--sm, max) {
    // cspell:disable-next-line
    background-image: url("data:image/svg+xml,%3Csvg width='686' height='664' viewBox='0 0 343 332' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='172.5' cy='195.5' r='187.5' transform='rotate(-90 172.5 195.5)' stroke='url(%23paint0_linear_28721_7416)' stroke-width='16'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_28721_7416' x1='172.5' y1='0' x2='172.5' y2='391' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='white'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0.16'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    flex-direction: column-reverse;
    background-position: bottom;
    background-size: 100% auto;
  }
  &__panel {
    &-image {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 50%;
      figure {
        position: relative;
        width: 100%;
        height: 100%;
        @include breakpoint($break--sm, max) {
          height: calc(100vw - $gutter--xxlg);
        }
      }
      @include breakpoint($break--sm, max) {
        width: 100%;
      }
    }
    &-content {
      width: 50%;
      color: $color-tertiary--white;
      padding: $gutter--xlg;
      @include breakpoint($break--sm, max) {
        width: 100%;
        padding: $gutter--md;
      }
      h1 {
        color: $color-tertiary--white;
        margin-bottom: 0;
      }
      h3 {
        color: $color-tertiary--white;
      }
      @extend %textListStyling;
      ul li.styled::before {
        background-color: $color-tertiary--white;
      }
    }
  }
}
