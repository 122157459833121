%heroHeaderMinHeight {
  min-height: $gutter--xlg * 2 - $gutter--sm;

  @include breakpoint($break--md, max) {
    min-height: $gutter--lg * 2;
  }

  @include breakpoint($break--sm, max) {
    min-height: $gutter--xxlg * 2;
  }
}

%heroContentMinHeight {
  min-height: 280px;
  padding-bottom: $gutter--xxlg * 1.5;

  @include breakpoint($break--md, max) {
    min-height: 238px;
    padding-bottom: $gutter--xlg * 2;
  }

  @include breakpoint($break--sm, max) {
    min-height: 158px;
    padding-bottom: $gutter--xlg * 1.5;
  }
}

.hero {
  position: relative;
  background-color: var(--color-primary-dark);
  color: $color-tertiary--white;
  width: 100%;
  max-width: $break--xxlg;
  margin: 0 auto;
  border-radius: 0;
  isolation: isolate;

  @include breakpoint($break--md, max) {
    width: 100%;
  }

  @include breakpoint($break--xxlg, min) {
    border-radius: 0px 0px 24px 24px;
  }

  &--personal {
    background-color: transparent;
    color: $color-tertiary--black;

    .hero__header {
      justify-content: flex-end;
    }
  }

  &--empty {
    padding-bottom: $gutter--lg;

    .hero__header {
      min-height: $gutter--lg * 2;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: $gutter--lg;
    min-height: $gutter--xlg * 2 - $gutter--sm;

    @include breakpoint($break--md, max) {
      min-height: $gutter--lg * 2;
    }

    @include breakpoint($break--sm, max) {
      min-height: 4.75rem;
    }

    &--carousel {
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 280px;
    padding-bottom: $gutter--xxlg * 2;

    @include breakpoint($break--md, max) {
      min-height: 238px;
    }

    @include breakpoint($break--sm, max) {
      min-height: 158px;
      padding-bottom: $gutter--lg * 4;
    }
  }

  .carousel {
    &__inner {
      padding-bottom: unset;
      height: unset;
    }
    &__slides {
      position: unset;
      &__slide {
        position: relative;
        padding-top: calc($gutter--xxlg * 2);
        @include breakpoint($break--md, max) {
          padding-top: calc($gutter--xlg * 2);
        }
      }
    }
    &__indicators {
      bottom: calc($gutter--lg * 2.5 + $gutter--xsm);
    }
    &__control {
      @include breakpoint($break--md, max) {
        bottom: calc($gutter--xlg + $gutter--xsm);
        top: unset;
        margin: 0 $gutter--md;
      }
      @include breakpoint($break--sm, max) {
        margin: 0 $gutter--xsm;
      }
    }
  }

  @extend %partnersListHero;

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      display: block;
      border-radius: 0;
      @include breakpoint($break--md, max) {
        border-radius: 0;
      }

      @include breakpoint($break--xxlg, min) {
        border-radius: 0px 0px 24px 24px;
      }
    }

    &:before {
      content: "";
      background: $gradient--primary;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 0;
      @include breakpoint($break--md, max) {
        border-radius: 0;
      }

      @include breakpoint($break--xxlg, min) {
        border-radius: 0px 0px 24px 24px;
      }
    }
  }

  &__title {
    color: inherit;
    margin-bottom: $gutter--md;

    &--darker {
      color: $color-primary--blue-darker;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include breakpoint($break--sm, max) {
      margin-bottom: 0;
    }
  }

  &__body {
    align-items: center;

    @include breakpoint($break--sm, max) {
      margin-bottom: $gutter--sm;
    }

    .sticker-group {
      flex-wrap: nowrap;
      margin-right: $gutter--sm;
    }
  }

  &__body-text p {
    color: inherit;
    font-size: $font-size--xlg;
    line-height: $font-size--xlg * 1.4;

    @include breakpoint($break--sm, max) {
      font-size: $font-size--md;
      line-height: $font-size--xlg * 1.2;
    }
  }

  &__cta {
    margin-top: $gutter--md;

    @include breakpoint($break--sm, max) {
      margin-top: $gutter--xsm;
      margin-bottom: $gutter--xsm;
    }
  }

  &.loading {
    @extend .loader--skeleton;
    @extend %loadingAnimationGradient;
  }
}
